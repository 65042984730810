import { Icon } from '../../atoms/Icon'

export const sideNavLinks = {
    top: [
        {
            name: 'Dashboard',
            link: '/',
            icon: <Icon type="3-cube" />,
        },
        {
            name: 'Manage Admin',
            link: '/manage-admin',
            icon: <Icon type="card-sm" />,
        },
        {
            name: 'Universities',
            link: '/universities',
            icon: <Icon type="courthouse" />,
        },
        {
            name: 'Users',
            link: '/users',
            icon: <Icon type="user-icon" />,
        },
        // {
        //   name: "SME Loans",
        //   link: "/sme-loans",
        //   icon: <Icon type="sme" />,
        //   items: [
        //     {
        //       name: "Personal Loan",
        //       link: "/sme-loans/personal",
        //     },
        //     {
        //       name: "Business Loan",
        //       link: "/sme-loans/business",
        //     },
        //   ],
        // },
        {
            name: 'Loans',
            link: '/loans',
            icon: <Icon type="wallet-sm" />,
        },
        {
            name: 'Withdrawals',
            link: '/withdrawals',
            icon: <Icon type="proof-of-funds-sm" />,
        },
        // {
        //     name: 'Short Term Loans',
        //     link: '/proof-of-funds',
        //     icon: <Icon type="proof-of-funds-sm" />,
        // },
        {
            name: 'Analytics',
            link: '/analytics',
            icon: <Icon type="analytics" />,
        },
        {
            name: 'Transactions',
            link: '/transactions',
            icon: <Icon type="ticket" />,
        },
        {
            name: 'Support Request',
            link: '/support',
            icon: <Icon type="support" />,
        },
    ],
    bottom: [
        // {
        //     name: 'Support Request',
        //     link: '/support',
        //     icon: <Icon type="support" />,
        // },
    ],
    settingLinks: [
        {
            name: 'Profile',
            link: '/settings/profile',
            icon: <Icon type="person" />,
        },
        {
            name: 'Login & Security',
            link: '/settings/login-security',
            icon: <Icon type="security" />,
        },
        {
            name: 'Preference',
            link: '/settings/preference',
            icon: <Icon type="preference" />,
        },
        {
            name: 'Price Setup',
            link: '/settings/pricing',
            icon: <Icon type="percentage" />,
        },
    ],
}
