import React from "react";
import styled from "styled-components";
import { EmailAndPhoneNumber, Modal } from "../../";
import { HorizontalLine, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import profileImage from "../../../../assets/pngs/profile-avatar.png";
import { africredColors } from "../../../../theme/colors";
import { format } from "date-fns";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 600px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const InfoWrapper = styled.div`
  padding: 16px 24px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
`;
const ProfileImageWrapper = styled.img`
  width: 76px;
  height: 76px;
  padding: 0.2rem;
`;
const CurrentStage = styled.div`
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${africredColors.primary.green[200]};
`;

export const UserModal = ({ open, setOpen, data }) => {
  if (data.accountType === "Agent" || data.accountType === "Student") {
    return (
      <Modal isOpen={open} positionX="end">
        <Wrapper>
          <div display="flex w-full">
            <Text
              className="w-5/6 mt-3"
              type="h3"
              textWrap="wrap"
              weight="medium"
            >
              Details
            </Text>

            <div className="flex flex-col gap-6 mt-6">
              <InfoWrapper>
                <div className="flex gap-4 items-center">
                  <ProfileImageWrapper
                    src={data.avatar?.Location || profileImage}
                    alt="profile"
                  />
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <Text type="h4">{data.fullName}</Text>
                      <CurrentStage>
                        <Text>{data.accountType}</Text>
                      </CurrentStage>
                    </div>
                    <EmailAndPhoneNumber
                      email={data?.email}
                      phoneNumber={data.phone}
                    />
                  </div>
                </div>
              </InfoWrapper>

              <HorizontalLine />

              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-1 gap-4">
                  <InfoWrapper>
                    <Text type="h5">Location</Text>
                    <Text type="p">{data.location}</Text>
                  </InfoWrapper>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <InfoWrapper>
                    <Text type="h5">Gender</Text>
                    <Text type="p">{data.gender}</Text>
                  </InfoWrapper>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <InfoWrapper>
                    <Text type="h5">Email</Text>
                    <Text type="p">{data.email}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Phone</Text>
                    <Text type="p">{data.phone}</Text>
                  </InfoWrapper>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <InfoWrapper>
                    <Text type="h5">Status</Text>
                    <Text type="p">{data.status}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Join date</Text>
                    <Text type="p">
                      {format(new Date(data.createdAt), "dd MM YYY")}
                    </Text>
                  </InfoWrapper>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
        <CloseButton onClick={() => setOpen(false)}>
          <Icon type="close" />
        </CloseButton>
      </Modal>
    );
  }

  // if (data.accountType === "Student") {
  //   return (
  //     <Modal isOpen={open} positionX="end">
  //       <Wrapper>
  //         <div display="flex w-full">
  //           <Text
  //             className="w-5/6 mt-3"
  //             type="h3"
  //             textWrap="wrap"
  //             weight="medium"
  //           >
  //             Details
  //           </Text>

  //           <div className="flex flex-col gap-6 mt-6">
  //             <InfoWrapper>
  //               <div className="flex gap-4 items-center">
  //                 <ProfileImageWrapper src={profileImage} alt="profile" />
  //                 <div className="flex flex-col gap-2">
  //                   <div className="flex gap-2 items-center">
  //                     <Text type="h4">{`${account?.firstName} ${account?.lastName}`}</Text>
  //                     <CurrentStage>
  //                       <Text>{data.accountType}</Text>
  //                     </CurrentStage>
  //                   </div>
  //                   <div className="flex gap-2">
  //                     <Text type="p">{`${account?.phoneNumber?.code}${account?.phoneNumber?.number}`}</Text>
  //                     <Text type="p">{account?.email}</Text>
  //                   </div>
  //                 </div>
  //               </div>
  //             </InfoWrapper>

  //             <HorizontalLine />

  //             <div className="flex flex-col gap-4">
  //               <Text type="p" weight="bold">
  //                 Application details
  //               </Text>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">First name</Text>
  //                   <Text type="p">{data.proofOfFunds?.firstName}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Last name</Text>
  //                   <Text type="p">{data.proofOfFunds?.lastName}</Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">Email</Text>
  //                   <Text type="p">{data.proofOfFunds?.email}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Phone</Text>
  //                   <Text type="p">
  //                     {data.proofOfFunds?.phoneNumber.number}
  //                   </Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">City of residence</Text>
  //                   <Text type="p">{data.proofOfFunds?.cityOfResidence}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Country</Text>
  //                   <Text type="p">{data.proofOfFunds?.countryOfStudy}</Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">Amount</Text>
  //                   <Text type="p">
  //                     {data.proofOfFunds?.currency}{" "}
  //                     {data.proofOfFunds?.pofAmount}
  //                   </Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Admission status</Text>
  //                   <Text type="p">
  //                     {data.proofOfFunds?.currency}{" "}
  //                     {data.proofOfFunds?.admissionStatus}
  //                   </Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-1 gap-4">
  //                 <InfoWrapper>
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <Text type="h5">School offer letter</Text>
  //                       <Text type="p">
  //                         {data.proofOfFunds.schoolOfferLetter
  //                           ? "Available"
  //                           : "Unavailable"}
  //                       </Text>
  //                     </div>
  //                     <div>
  //                       <a
  //                         href={data.proofOfFunds.schoolOfferLetter.Location}
  //                         download={`${data.proofOfFunds.firstName}-school-offer-letter`}
  //                         target="_blank"
  //                         rel="noreferrer"
  //                       >
  //                         <Icon type="download-doc" />
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-1 gap-4">
  //                 <InfoWrapper>
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <Text type="h5">Proof of ID</Text>
  //                       <Text type="p">
  //                         {
  //                           data.proofOfFunds.proofOfIdentity
  //                             .typeOfProofOfIdentity
  //                         }
  //                       </Text>
  //                     </div>
  //                     <div>
  //                       <a
  //                         href={data.proofOfFunds.proofOfIdentity.file.Location}
  //                         download={`${data.proofOfFunds.firstName}-proof-of-identity`}
  //                         target="_blank"
  //                         rel="noreferrer"
  //                       >
  //                         <Icon type="download-doc" />
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </InfoWrapper>
  //               </div>
  //             </div>

  //             <HorizontalLine />
  //           </div>
  //         </div>
  //       </Wrapper>
  //       <CloseButton onClick={() => setOpen(false)}>
  //         <Icon type="close" />
  //       </CloseButton>
  //     </Modal>
  //   );
  // }

  // if (data.transactionType.toLowerCase() === "Partner") {
  //   return (
  //     <Modal isOpen={open} positionX="end">
  //       <Wrapper>
  //         <div display="flex w-full">
  //           <Text
  //             className="w-5/6 mt-3"
  //             type="h3"
  //             textWrap="wrap"
  //             weight="medium"
  //           >
  //             Details
  //           </Text>

  //           <div className="flex flex-col gap-6 mt-6">
  //             <InfoWrapper>
  //               <div className="flex gap-4 items-center">
  //                 <ProfileImageWrapper src={profileImage} alt="profile" />
  //                 <div className="flex flex-col gap-2">
  //                   <div className="flex gap-2 items-center">
  //                     <Text type="h4">{`${account?.firstName} ${account?.lastName}`}</Text>
  //                     <CurrentStage>
  //                       <Text>{data.accountType}</Text>
  //                     </CurrentStage>
  //                   </div>
  //                   <div className="flex gap-2">
  //                     <Text type="p">{`${account?.phoneNumber?.code}${account?.phoneNumber?.number}`}</Text>
  //                     <Text type="p">{account?.email}</Text>
  //                   </div>
  //                 </div>
  //               </div>
  //             </InfoWrapper>

  //             <HorizontalLine />

  //             <div className="flex flex-col gap-4">
  //               <Text type="p" weight="bold">
  //                 Application details
  //               </Text>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">First name</Text>
  //                   <Text type="p">{data.loan?.firstName}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Last name</Text>
  //                   <Text type="p">{data.loan?.lastName}</Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">Email</Text>
  //                   <Text type="p">{data.loan?.email}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Phone</Text>
  //                   <Text type="p">{data.loan?.phoneNumber.number}</Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">City of residence</Text>
  //                   <Text type="p">{data.loan?.cityOfResidence}</Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Country</Text>
  //                   <Text type="p">{data.loan?.countryOfStudy}</Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-2 gap-4">
  //                 <InfoWrapper>
  //                   <Text type="h5">Amount</Text>
  //                   <Text type="p">
  //                     {data.loan?.currency} {data.loan?.loanAmount}
  //                   </Text>
  //                 </InfoWrapper>
  //                 <InfoWrapper>
  //                   <Text type="h5">Admission status</Text>
  //                   <Text type="p">
  //                     {data.loan?.currency} {data.loan?.admissionStatus}
  //                   </Text>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-1 gap-4">
  //                 <InfoWrapper>
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <Text type="h5">School offer letter</Text>
  //                       <Text type="p">
  //                         {data.loan.schoolOfferLetter
  //                           ? "Available"
  //                           : "Unavailable"}
  //                       </Text>
  //                     </div>
  //                     <div>
  //                       <a
  //                         href={data.loan.schoolOfferLetter.Location}
  //                         download={`${data.loan.firstName}-school-offer-letter`}
  //                         target="_blank"
  //                         rel="noreferrer"
  //                       >
  //                         <Icon type="download-doc" />
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </InfoWrapper>
  //               </div>
  //               <div className="grid grid-cols-1 gap-4">
  //                 <InfoWrapper>
  //                   <div className="flex justify-between">
  //                     <div>
  //                       <Text type="h5">Proof of ID</Text>
  //                       <Text type="p">
  //                         {data.loan.proofOfIdentity.typeOfProofOfIdentity}
  //                       </Text>
  //                     </div>
  //                     <div>
  //                       <a
  //                         href={data.loan.proofOfIdentity.file.Location}
  //                         download={`${data.loan.firstName}-proof-of-identity`}
  //                         target="_blank"
  //                         rel="noreferrer"
  //                       >
  //                         <Icon type="download-doc" />
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </InfoWrapper>
  //               </div>
  //             </div>

  //             <HorizontalLine />
  //           </div>
  //         </div>
  //       </Wrapper>
  //       <CloseButton onClick={() => setOpen(false)}>
  //         <Icon type="close" />
  //       </CloseButton>
  //     </Modal>
  //   );
  // }

  return null;
};
