import React from "react";
import { Text } from "../../atoms";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";
import { africredColors } from "../../../theme/colors";

const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  /* min-height: 90vh; */
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 24px;
  width: 100%;

  @media only screen and (${screen.md}) {
    padding-bottom: 0;
  }

  .faded-text {
    color: ${africredColors.neutrals.night[500]}
  }
`;
const FormSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 85vh; */
  width: 100%;
`;
const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 16px;
  height: fit-content;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1cap;

  @media only screen and (${screen.md}) {
    width: 70%;
  }
`;

export const UploadUniversityTemplate = ({ children, className }) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Text textalign="center" type="h2">
          Batch Upload College or University
        </Text>
        <p className="w-5/6 text-center">
          Spreadsheet programs (like Excel or Numbers) can save sheets or tables as XLSX files
        </p>
      </TitleWrapper>
      <FormSectionWrapper>
        <FormSection
          className={`flex flex-col justify-center items-center gap-4 rounded ${
            className || "w-full sm:w-3/5 xl:w-2/5"
          }`}
        >

          <div className="w-full">{children}</div>
        </FormSection>
      </FormSectionWrapper>
    </Wrapper>
  );
};
