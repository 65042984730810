import { africredColors } from "./theme/colors";
import parsePhoneNumber from "libphonenumber-js";

export const pageAnimationConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1.4 },
};

export const toSentenceCase = (str) => {
  if (!str) return "";
  return str
    .toLowerCase()
    .replace(/\.\s+([a-z])[^.]|^(\s*[a-z])[^.]/g, (s) =>
      s.replace(/([a-z])/, (s) => s.toUpperCase())
    );
};

export function getLabelColor(status) {
  if (status === "InReview" || status === "Draft" || status === "Pof")
    return africredColors.neutrals.night[950];
  if (status === "Accept") return africredColors.primary.green[950];
  if (status === "Has EOI" || status === "Remittance")
    return africredColors.primary.blue[950];
  if (status === "Logged In") return africredColors.primary.gold[950];
  if (status === "Rejected" || status === "Cancelled")
    return africredColors.secondary.red[950];
  if (status === "Accept" || status === "Sent To Partner" || status === "Loan")
    return africredColors.primary.green[950];
  if (status === "Disbursed") return africredColors.primary.green[950];
}
export function getLabelBgColor(status) {
  if (status === "InReview" || status === "Draft" || status === "Pof")
    return africredColors.neutrals.night[50];
  if (status === "Accept") return africredColors.primary.green[50];
  if (status === "Has EOI" || status === "Remittance")
    return africredColors.primary.blue[50];
  if (status === "Logged In") return africredColors.primary.gold[50];
  if (status === "Rejected" || status === "Cancelled")
    return africredColors.secondary.red[50];
  if (status === "Accept" || status === "Sent To Partner" || status === "Loan")
    return africredColors.primary.green[50];
  if (status === "Disbursed") return africredColors.primary.green[50];
}

export function camelCaseToSeparateWords(str) {
  if (!str) return "";
  return str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
}

export function separatePhoneNumberString(phoneNumber, countryCode = "NG") {
  const firstDigit = phoneNumber.split("")[0] !== "+" ? "+" : "";
  const parsedPhoneNumber = getParsedPhoneNumber(phoneNumber, countryCode);

  if (!phoneNumber || !parsedPhoneNumber) {
    return {
      code: null,
      number: null,
    };
  }

  return {
    code: firstDigit + parsedPhoneNumber.countryCallingCode,
    number: parsedPhoneNumber.nationalNumber,
  };
}

export function getParsedPhoneNumber(number, countryCode = "NG") {
  const phoneNumber = parsePhoneNumber(number, countryCode.toUpperCase());
  if (phoneNumber) {
    return phoneNumber;
  }
  return null;
}

export function filterLoan(filterData) {
  return function (item) {
    return (
      item.amount.includes(filterData.searchTerm) ||
      item.referenceNumber
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase()) ||
      item.issuedBy
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase()) ||
      item.purpose
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase()) ||
      item.acceptedBy
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase())
    );
  };
}

export function filterSmeLoan(filterData) {
  const searchTerm = filterData.searchTerm?.toLowerCase();
  return function (item) {
    return (
      item.fullName?.toLowerCase().includes(searchTerm) ||
      item.email?.toLowerCase().includes(searchTerm)
    );
  };
}

export function filterSupport(filterData) {
  if (
    filterData.sort?.toLowerCase() === "category" ||
    filterData.sort?.toLowerCase() === "others"
  ) {
    return function (item) {
      return (
        item.category
          .toLowerCase()
          .includes(filterData.searchTerm?.toLowerCase()) ||
        item.issuedBy
          .toLowerCase()
          .includes(filterData.searchTerm?.toLowerCase()) ||
        item.status.toLowerCase().includes(filterData.searchTerm?.toLowerCase())
      );
    };
  }

  return function (item) {
    return (
      item.category.toLowerCase() === filterData.sort?.toLowerCase() &&
      (item.category
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase()) ||
        item.issuedBy
          .toLowerCase()
          .includes(filterData.searchTerm?.toLowerCase()) ||
        item.status
          .toLowerCase()
          .includes(filterData.searchTerm?.toLowerCase()))
    );
  };
}

export function sortWithDate(filterData) {
  return function (a, b) {
    if (filterData.sort.toLowerCase() === "asc")
      return new Date(b.date) - new Date(a.date);
    return new Date(a.date) - new Date(b.date);
  };
}

export function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Helper function to get the ordinal suffix for a day
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Parse the input date string
  const date = new Date(dateString);

  // Ensure the date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date string");
  }

  // Get the day of the month, month, and year from the date
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Combine the parts with the ordinal suffix for the day
  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}

export function filterUser(filterData) {
  return function (item) {
    return (
      item.email?.includes(filterData.searchTerm) ||
      item.phone?.toLowerCase().includes(filterData.searchTerm?.toLowerCase()) ||
      item.fullName?.toLowerCase().includes(filterData.searchTerm?.toLowerCase()) ||
      item.accountType?.toLowerCase().includes(filterData.searchTerm?.toLowerCase()) ||
      item.gender?.toLowerCase().includes(filterData.searchTerm?.toLowerCase()) ||
      item.location?.toLowerCase().includes(filterData.searchTerm?.toLowerCase())
    );
  };
}

export function filterAdmin(filterData) {
  return function (item) {
    return (
      item.email.includes(filterData.searchTerm) ||
      item.fullName
        .toLowerCase()
        .includes(filterData.searchTerm?.toLowerCase()) ||
      item.role.toLowerCase().includes(filterData.searchTerm?.toLowerCase()) ||
      item.status.toLowerCase().includes(filterData.searchTerm?.toLowerCase())
    );
  };
}

export function formatNumber(price) {
  return new Intl.NumberFormat().format(price);
}
