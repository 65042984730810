import React from 'react'
import { DatePicker } from 'antd'

import { Icon, Input, SelectInput } from '../../atoms'
import { africredColors } from '../../../theme/colors'

const { RangePicker } = DatePicker

export const TableFilter = ({
    filterData,
    setFilterData,
    sortOptions = ['Asc', 'Dsc'],
    loanTypeOptions = ['Business Loan', 'Personal Loan'],
    showLoanType,
    loanType,
    showSort,
    showDateRange,
    showsCsvButton,
    handleLoanTypeChange,
    csvFileName,
    apiRef,
    accountStatusOptions = ['Active'],
    accountTypeOptions = ['Student', 'Partner', 'Agent'],
    loanStatusOptions = [],
    showAccountType,
    showAccountStatus,
    showLoanStatus,
    handleExport,
}) => {
    const handleExportClick = () => {
        if (handleExport) {
            return handleExport()
        }
        
        if (!apiRef.current) {
            return
        }

        const exportOptions = {
            fileName: `${csvFileName || 'Pro U'}`,
            allColumns: true,
        }

        apiRef.current.exportDataAsCsv(exportOptions)
    }

    return (
        <div className="flex gap-x-4 gap-y-2 flex-wrap">
            <div className="w-full md:w-[275px]">
                <Input
                    type="search"
                    size="sm"
                    value={filterData?.searchTerm}
                    icon={<Icon type="search" />}
                    paddingleft="3rem"
                    bgcolor="transparent"
                    placeholder="search"
                    onChange={e =>
                        setFilterData(prev => ({
                            ...prev,
                            searchTerm: e.target.value,
                        }))
                    }
                    border={`1px solid ${africredColors.neutrals.night[300]}`}
                />
            </div>
            {showDateRange && (
                <RangePicker
                    style={{
                        borderRadius: '30px',
                        outline: 'none',
                        border: `1px solid ${africredColors.neutrals.night[300]}`,
                    }}
                    value={filterData.dateRange}
                    onChange={(val, strArr) => {
                        setFilterData(prev => ({ ...prev, dateRange: val }))
                    }}
                />
            )}
            {showSort && (
                <div className="w-full md:w-[200px]">
                    <SelectInput
                        options={sortOptions}
                        value={filterData?.sort || 'Sort'}
                        setValue={val =>
                            setFilterData(prev => ({ ...prev, sort: val }))
                        }
                        size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        color="#5D5D5D"
                        caret="down-caret-light"
                    />
                </div>
            )}

            {showLoanType && (
                <div className="w-full md:w-[220px]">
                    <SelectInput
                        options={loanTypeOptions}
                        value={loanType}
                        setValue={handleLoanTypeChange}
                        size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        color="#5D5D5D"
                        caret="down-caret-light"
                    />
                </div>
            )}
            {showAccountType && (
                <div className="w-full md:w-[220px]">
                    <SelectInput
                        options={accountTypeOptions}
                        value={filterData?.accountType}
                        setValue={val =>
                            setFilterData(prev => ({
                                ...prev,
                                accountType: val,
                            }))
                        }
                        size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        color="#5D5D5D"
                        caret="down-caret-light"
                        placeholder="Account Type"
                    />
                </div>
            )}
            {showAccountStatus && (
                <div className="w-full md:w-[220px]">
                    <SelectInput
                        options={accountStatusOptions}
                        value={filterData?.accountStatus}
                        setValue={val =>
                            setFilterData(prev => ({
                                ...prev,
                                accountStatus: val,
                            }))
                        }
                        size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        color="#5D5D5D"
                        caret="down-caret-light"
                    />
                </div>
            )}
            {showLoanStatus && (
                <div className="w-full md:w-[220px]">
                    <SelectInput
                        options={loanStatusOptions}
                        value={filterData?.loanStatus}
                        setValue={val =>
                            setFilterData(prev => ({
                                ...prev,
                                loanStatus: val,
                            }))
                        }
                        size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        color="#5D5D5D"
                        caret="down-caret-light"
                        placeholder="Status"
                    />
                </div>
            )}
            {showsCsvButton && (
                <div className="w-full md:w-[250px]">
                    <button
                        className={`h-12 w-full flex items-center justify-center gap-2 rounded-full border-[1px] border-[${africredColors.neutrals.night[300]}] text-[#5D5D5D]`}
                        onClick={handleExportClick}
                    >
                        <Icon type="download" />
                        Download CSV
                    </button>
                </div>
            )}
        </div>
    )
}
