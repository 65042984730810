import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { Button, Icon, Input, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import profileImg from "../../../assets/pngs/dp.png";
import { screen } from "../../../theme";
// import { InputWithAddonSelect, fetchFlag } from "../../molecules";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthContext from "../../../store/contexts/AuthProvider";
import { useScreenSize } from "../../../hooks/useScreenSize";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: block;
  padding: 12px 12px 48px 12px;
  gap: 10px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;

  @media only screen and (${screen.xl}) {
    display: flex;
    padding: 48px 96px;
  }
`;
const InputArea = styled.div`
  padding: 24px;

  @media only screen and (${screen.md}) {
    padding-right: 56px;
  }
`;
const InputRow = styled.div`
  display: flex;
  gap: 12px;
`;
const InputRowWrapper = styled.div`
  border-top: 1px solid ${africredColors.neutrals.night[200]};
  padding: 32px 0;
`;
const ProfilePictureRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
  align-items: center;

  > img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
`;
const ConfirmedInformation = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-left: 0;

  @media only screen and (${screen.md}) {
    padding: 0 56px;
    border-left: 1px solid ${africredColors.neutrals.night[200]};
  }
`;
const ProfilePicUploadInput = styled.input`
  width: 0;
  visibility: hidden;
`;

export const ProfileSettings = () => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();
  const { setAuth } = useContext(AuthContext);
  const isMobile = useScreenSize();
  const navigate = useNavigate();

  // Image upload code starts
  const inputImageRef = useRef(null);
  const facePhoto = auth?.profilePicture?.Location
    ? auth?.profilePicture?.Location
    : profileImg;
  const [photo, setPhoto] = useState(facePhoto);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  useEffect(() => {
    setPhoto(facePhoto);
  }, [facePhoto, setPhoto]);

  async function handlePhotoInput(e) {
    const image = URL.createObjectURL(e.target.files[0]);

    setPhoto(image);
    const imageFormData = new FormData();
    imageFormData.append("file", e.target.files[0]);

    const response = await axiosPrivate.post(
      "/admin/upload-profile-picture",
      imageFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    if (response.data) {
      setAuth((prev) => ({
        ...prev,
        profilePicture: response.data.profilePicture,
      }));
    }
  }

  // image upload code ends
  async function sendEmailOTP() {
    if (!email) {
      alert("You can't send without an email");
      return false;
    }
    try {
      const response = await axios.post(`/admin/send-otp/${email}`);

      if (response.data) {
        navigate(`/update-contact/email?id=${response.data.id}&email=${email}`);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  async function sendPhoneOTP() {
    if (!isPhoneValid || phoneNumber.length < 11) {
      toast.info("Please check your phone number and try again");
      return false;
    }
    try {
      const response = await axiosPrivate.post(`/admin/send-phone-number-otp`, {
        phoneNumber,
      });

      if (response.data) {
        navigate(
          `/update-contact/phone?id=${response.data.otp.id}&phoneNumber=${phoneNumber}`
        );
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  return (
    <Wrapper {...pageAnimationConfig}>
      <Text type="h3" weight="medium">
        Profile
      </Text>
      <WhiteArea>
        <InputArea className="w-full xl:w-3/6">
          <ProfilePictureRow>
            <img src={photo} alt="profile-img" />
            <Button
              size="md"
              border={`1px solid ${africredColors.neutrals.night[950]}`}
              onClick={() => inputImageRef?.current?.click?.()}
            >
              {!isMobile && <Icon type="image" />}
              <Text type="p" weight="bold" textWrap="nowrap">
                Upload photo
              </Text>
            </Button>
            <ProfilePicUploadInput
              ref={inputImageRef}
              type="file"
              accept="image/*"
              onChange={(e) => {
                const response = handlePhotoInput(e);
                toast.promise(response, {
                  pending: "Uploading image",
                  success: "Upload successful 👌",
                  error: "Upload failed 🤯",
                });
              }}
            />
          </ProfilePictureRow>
          <InputRowWrapper>
            <Text type="h5" weight="medium" margin="0 0 1rem 0">
              Email
            </Text>
            <InputRow>
              <Input
                size="md"
                icon={<Icon type="email" />}
                placeholder="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                size="md"
                border={`1px solid ${africredColors.neutrals.night[950]}`}
                padding="0 1.5rem"
                onClick={sendEmailOTP}
              >
                <Text type="p" weight="medium">
                  Update
                </Text>
              </Button>
            </InputRow>
          </InputRowWrapper>
          <InputRowWrapper>
            <Text type="h5" weight="medium" margin="0 0 1rem 0">
              Phone number
            </Text>
            <InputRow>
              <Input
                size="md"
                type="phone"
                value={phoneNumber}
                onChange={(val) => setPhoneNumber(val)}
                setIsPhoneValid={setIsPhoneValid}
              />
              <Button
                onClick={sendPhoneOTP}
                size="md"
                border={`1px solid ${africredColors.neutrals.night[950]}`}
                padding="0 1.5rem"
              >
                <Text type="p" weight="medium">
                  Update
                </Text>
              </Button>
            </InputRow>
          </InputRowWrapper>
        </InputArea>
        <ConfirmedInformation className="w-full xl:w-2/6">
          <div>
            <Text type="h5" weight="bold" margin="0 0 1rem 0">
              {auth?.firstName}'s confirmed information
            </Text>
            <div className="flex items-center gap-4">
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <Text type="p">Email address</Text>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <Text type="p">Phone number</Text>
            </div>
          </div>
        </ConfirmedInformation>
      </WhiteArea>
    </Wrapper>
  );
};
