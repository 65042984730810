import React, { useState } from "react";
import { Button, Icon, Input } from "../../atoms";
import { brandTheme } from "../../../theme";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { ResetPasswordLinkConfirmation } from "./ResetPasswordLinkConfirmation";
import axios from "../../../api/axios";

export const ForgotPassword = () => {
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [email, setEmail] = useState("");

  async function sendResetLink() {
    try {
      const response = await axios.post("/admin/send-reset-link", {
        email,
      });

      if (response.data) setResetLinkSent(true);
    } catch (err) {}
  }

  return (
    <>
      {!resetLinkSent ? (
        <AuthTemplate
          title="Forgot password"
          subtitle="Please enter your email to receive a link to reset password"
        >
          <Input
            icon={<Icon type="email" />}
            type="email"
            size="md"
            placeholder="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={sendResetLink}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.primary}
          >
            Continue
          </Button>
        </AuthTemplate>
      ) : (
        <ResetPasswordLinkConfirmation />
      )}
    </>
  );
};
