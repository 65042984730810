import React from "react";
import { Button, Icon, Text } from "../../../atoms";
import { africredColors } from "../../../../theme/colors";
import logo from "../../../../assets/svgs/full_logo.svg";
import { RequestInfoModal } from "../RequestInfoModal";

export const SuccessModal = ({
  title,
  subTitle = "A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets.",
  ctaText,
  openModal,
  bgcolor = `${africredColors.primary.gold[500]}`,
  color = "white",
}) => {
  return (
    <RequestInfoModal open={openModal} closeable={false}>
      <div className="flex h-[95vh] justify-center items-center">
        <div className="flex flex-col gap-4 items-center">
          <img src={logo} alt="logo" />
          <div className="mt-4">
            <Icon type="success-icon" />
          </div>
          <Text
            className="w-5/6 mt-4"
            type="h3"
            textWrap="wrap"
            weight="medium"
            textalign="center"
          >
            {title}
          </Text>
          <Text className="w-5/6" type="p" textalign="center" textWrap="wrap">
            {subTitle}
          </Text>
          <div className="flex justify-center mt-4">
            <Button
              onClick={() => {
                window.location.reload();
              }}
              width="300px"
              size="md"
              bgcolor={bgcolor}
              color={color}
            >
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    </RequestInfoModal>
  );
};
