import React from "react";
import styled from "styled-components";
import { Button, Icon } from "../../atoms";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/svgs/full_logo.svg";
import { africredColors } from "../../../theme/colors";
import { brandTheme, screen } from "../../../theme";
import { motion } from "framer-motion";
import { pageAnimationConfig } from "../../../utils";

const Wrapper = styled(motion.div)`
  background-color: white;
  height: 100%;
  width: 100%;
  background-color: ${brandTheme.brand.backgroundSkin};
  position: relative;
  overflow-y: auto;
`;
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 8px 16px;
  border-bottom: 1px solid ${africredColors.neutrals.night[300]};
  height: 74px;

  @media only screen and (${screen.md}) {
    padding: 16px 60px;
  }
`;
const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 74px);
  align-items: center;
  padding: 1rem;
`;
const Image = styled.img`
  width: 90px;

  @media only screen and (${screen.md}) {
    width: 100%;
  }
`;

export const CloseablePageTemplage = ({ onClick, children }) => {
  const navigate = useNavigate();

  return (
    <Wrapper {...pageAnimationConfig}>
      <StyledNav>
        <div>
          <Link to="/">
            <Image src={logo} alt="logo" />
          </Link>
        </div>

        <div className="flex gap-4">
          <Button
            size="sm"
            border={`1px solid ${africredColors.neutrals.night[950]}`}
            onClick={() => {
              onClick && onClick();
              navigate(-1);
            }}
          >
            <Icon type="close-sm" />
          </Button>
        </div>
      </StyledNav>
      <ContentArea>{children}</ContentArea>
    </Wrapper>
  );
};
