import React from "react";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button } from "../../atoms";
import { brandTheme } from "../../../theme";
import { Link } from "react-router-dom";

export const ResetPasswordLinkConfirmation = () => {
  return (
    <AuthTemplate
      title="Reset password link sent"
      subtitle="We sent a message to Ric....@gmail.com with a link to reset your password"
    >
      <Link to="/reset-password">
        <Button width="100%" size="md" bgcolor={brandTheme.brand.primary}>
          Open email app
        </Button>
      </Link>
    </AuthTemplate>
  );
};
