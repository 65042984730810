import React, { useState, useEffect } from 'react'
import { Text } from '../../atoms/Text'
import { Icon } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Table } from '../../molecules'
import { Link, useSearchParams } from 'react-router-dom'
import profileImage from '../../../assets/pngs/dp.png'
import {
    filterLoan,
    getLabelBgColor,
    getLabelColor,
    pageAnimationConfig,
    sortWithDate,
    formatDate,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { screen } from '../../../theme'
import { TableFilter } from '../../organisms'
import { useQuery } from '@tanstack/react-query'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { TransactionDetailsModal } from '../../molecules/Modal/TransactionDetailsModal'
import { useGridApiRef } from '@mui/x-data-grid'
import { toast } from 'react-toastify';

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const ClickAbleLabel = styled(Link)`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 48px;
`
const Flex = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Transactions = () => {
    const axiosPrivate = useAxiosPrivate()
    const { isMobile } = useScreenSize()
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        dateRange: [],
    })
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [transactionDetails, setTransactionDetails] = useState(null)
    const [loadingFetch, setLoadingFetch] = useState(false);
    const [params] = useSearchParams();
    const searchedId = params.get('id');
    const apiRef = useGridApiRef()

    const {
        isPending,
        error,
        data: transactions,
        refetch,
    } = useQuery({
        queryKey: ['transactions'],
        queryFn: async () => {
            const response = await axiosPrivate.get(
                `/transaction/search?${
                    filterData.searchTerm
                        ? `searchTerm=${filterData.searchTerm}`
                        : ''
                }
        ${
            filterData.dateRange?.length
                ? `&startDate=${filterData.dateRange[0].format(
                      'YYYY-MM-DD',
                  )}&endDate=${filterData.dateRange[1].format('YYYY-MM-DD')}`
                : ''
        }`,
            )
            return response.data.foundTransactions.map(item => {
                // `${item.account.firstName} ${item?.account?.lastName}`
                return {
                    ...item,
                    id: item.referenceNumber,
                    referenceNumber: item.referenceNumber
                        ? item.referenceNumber
                        : '...',
                    avatar: item.account.profilePicture?.Location,
                    acceptedBy: item.partner
                        ? `${item.partner?.firstName} ${item.partner?.lastName}`
                        : '...',
                    issuedBy: `${item.account.firstName} ${item.account.lastName}`,
                    amount: `${item.amount}`,
                    purpose: item.purpose,
                    transactionType: item.transactionType,
                    date: item.createdAt,
                    status: item.paymentStatus,
                }
            })
        },
    })

    useEffect(() => {
        if (searchedId) {
          loadSearchedData(searchedId);
        }
      }, [searchedId]);
    
      async function loadSearchedData(id) {
        setLoadingFetch(true);
        try {
            const response = await axiosPrivate(
              `/transaction/search?id[0]=${searchedId}`
            );
    
            if (response.data && response.data.foundTransactions?.length) {
              const item = response.data.foundTransactions[0];
              setTransactionDetails({
                ...item,
                id: item.referenceNumber,
                referenceNumber: item.referenceNumber
                    ? item.referenceNumber
                    : '...',
                avatar: item.account.profilePicture?.Location,
                acceptedBy: item.partner
                    ? `${item.partner?.firstName} ${item.partner?.lastName}`
                    : '...',
                issuedBy: `${item.account.firstName} ${item.account.lastName}`,
                amount: `${item.amount}`,
                purpose: item.purpose,
                transactionType: item.transactionType,
                date: item.createdAt,
                status: item.paymentStatus,
              });
              setOpenDetailsModal(true);
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "Something went wrong")
        }
        setLoadingFetch(false)
    }
    
    const columns = [
        {
            field: 'id',
            headerName: 'Transaction Id',
            minWidth: 100,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'purpose',
            headerName: 'Purpose',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'transactionType',
            headerName: 'type',
            minWidth: 160,
            flex: 2.4,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <ClickAbleLabel
                        color={getLabelColor(value)}
                        backgroundcolor={getLabelBgColor(value)}
                    >
                        {value}
                    </ClickAbleLabel>
                )
            },
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="View Details"
                        showInMenu
                        onClick={() => {
                            setTransactionDetails(params.row)
                            if (transactionDetails) {
                                setOpenDetailsModal(true)
                            }
                        }}
                    />,
                ],
            ],
        },
    ]

    useEffect(() => {
        if (!isPending) {
            refetch()
        }
    }, [filterData.searchTerm, filterData.dateRange])

    if (isPending || loadingFetch)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    Transactions
                </Text>
                <Text type="p" weight="normal">
                    Total transactions
                </Text>
            </div>
            <ContentWrapper>
                <div className="flex justify-between gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    {/* <Text type="h5" weight="medium">
                        Latest transactions
                    </Text> */}
                    <TableFilter
                        showDateRange
                        showsCsvButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="ProU Transactions"
                    />
                </div>

                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={
                            [...transactions].sort(sortWithDate(filterData))
                            // .filter(filterLoan(filterData))
                        }
                        pageSize="8"
                    />
                </TableWrapper>
            </ContentWrapper>
            {transactionDetails && (
                <TransactionDetailsModal
                    title="Details"
                    open={openDetailsModal}
                    setOpen={setOpenDetailsModal}
                    data={transactionDetails}
                />
            )}
        </Wrapper>
    )
}
