import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "../../atoms/Text";
import { africredColors } from "../../../theme/colors";
import { sideNavLinks } from "./sideNavLinks";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { brandTheme, screen } from "../../../theme";
import { NavBar } from "../../molecules/NavBar";
import { MobileSideNav } from "../../molecules/Modal/SideNav";
import { Icon } from "../../atoms";

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${brandTheme.brand.backgroundSkin};
`;

const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  background-color: inherit;

  @media only screen and (${screen.md}) {
    flex-direction: row;
    padding-top: 97px;
  }
`;

const sideNavCSS = css`
  height: calc(100vh - 94px);
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 300px;
  padding: 32px 20px 20px 20px;
  background-color: inherit;

  a:hover,
  .active,
  .list-nav:hover,
  .list-nav-active {
    border-radius: 24px;
    background-color: ${africredColors.neutrals.night[100]};

    p {
      font-weight: bold;
    }
  }

  @media only screen and (${screen.md}) {
    display: flex;
  }
`;

const SideNav = styled.aside`
  display: none;
  ${sideNavCSS};
`;

const SideNavCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MobileSideNavWrapper = styled.aside`
  ${sideNavCSS};
  width: 100%;
  padding: 32px 0;
  height: 87vh;
`;

const MainContent = styled.section`
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 32px 12px 12px 12px;

  @media only screen and (${screen.md}) {
    padding: 32px 20px 20px 20px;
  }
`;

const SideNavItems = styled(NavLink)`
  display: flex;
  padding: 16px 24px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DashboardTemplate = () => {
  // const auth = useAuth();
  const settingsNavLinks = sideNavLinks.settingLinks;
  const location = useLocation();
  const isSettingsPage = location.pathname.split("/").includes("settings");
  const topLink = isSettingsPage ? settingsNavLinks : sideNavLinks.top;
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  useEffect(() => {
    setIsSideNavOpen(false);
  }, [location]);

  return (
    <Wrapper>
      <NavBar setSideNav={setIsSideNavOpen} />

      <ContentArea>
        <SideNav>
          <SideNavCol>
            {isSettingsPage && <Text type="small">SETTINGS</Text>}
            {topLink.map((linkItem) => {
              if (linkItem.items) {
                return <ListNav linkItem={linkItem} />;
              }

              return (
                <SideNavItems to={linkItem.link} key={linkItem.name}>
                  <div>{linkItem.icon}</div>
                  <Text type="p">{linkItem.name}</Text>
                </SideNavItems>
              );
            })}
          </SideNavCol>

          {!isSettingsPage && (
            <SideNavCol>
              {sideNavLinks.bottom.map((linkItem) => (
                <SideNavItems to={linkItem.link} key={linkItem.name}>
                  <div>{linkItem.icon}</div>
                  <Text type="p">{linkItem.name}</Text>
                </SideNavItems>
              ))}
            </SideNavCol>
          )}
        </SideNav>
        <MainContent>
          <Outlet />
        </MainContent>
      </ContentArea>
      {isSideNavOpen && (
        <MobileSideNav isOpen={isSideNavOpen} setIsOpen={setIsSideNavOpen}>
          <MobileSideNavWrapper>
            <SideNavCol>
              {isSettingsPage && <Text type="small">SETTINGS</Text>}
              {topLink.map((linkItem) => (
                <SideNavItems to={linkItem.link} key={linkItem.name}>
                  <div>{linkItem.icon}</div>
                  <Text type="p">{linkItem.name}</Text>
                </SideNavItems>
              ))}
            </SideNavCol>

            {!isSettingsPage && (
              <SideNavCol>
                {sideNavLinks.bottom.map((linkItem) => (
                  <SideNavItems to={linkItem.link} key={linkItem.name}>
                    <div>{linkItem.icon}</div>
                    <Text type="p">{linkItem.name}</Text>
                  </SideNavItems>
                ))}
              </SideNavCol>
            )}
          </MobileSideNavWrapper>
        </MobileSideNav>
      )}
    </Wrapper>
  );
};

const ListNav = ({ linkItem }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <div
        className={`list-nav flex items-center gap-2 py-4 px-6 ${location.pathname.includes(linkItem.link) ? "list-nav-active" : ""
          } cursor-pointer`}
        onClick={toggleOpen}
      >
        <div>{linkItem.icon}</div>
        <Text type="p">{linkItem.name}</Text>
        <Icon type="caret" />
      </div>
      {open ? (
        <ul className="flex flex-col gap-1">
          {linkItem.items.map((item, idx) => (
            <NavLink key={idx} to={item.link} className="py-2 pl-12 pr-4">
              <li>{item.name}</li>
            </NavLink>
          ))}
        </ul>
      ) : (
        ""
      )}
    </>
  );
};
