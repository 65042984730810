import React from "react";
import styled from "styled-components";
import { Modal } from "../../";
import { Button, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import { africredColors } from "../../../../theme/colors";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 600px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const CategoryWrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  width: fit-content;
  border-radius: 36px;
  background: ${africredColors.neutrals.night[80]};
  margin-top: 48px;
  margin-bottom: 64px;
`;
const CategoryText = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid ${africredColors.neutrals.night[600]};
`;
const MessageWrapper = styled.div`
  overflow-y: auto;
  max-height: 50vh;
`;
const ReplyLink = styled.a`
  display: block;
  width: 100%;
`;

export const SupportMessageModal = ({ open, setOpen, data }) => {
  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        <div display="flex w-full">
          <Text
            className="w-5/6 mt-3"
            type="h3"
            textWrap="wrap"
            weight="medium"
          >
            Description
          </Text>

          <CategoryWrapper>
            <Text type="p">category</Text>
            <CategoryText className="flex">
              <div>
                <Icon type="category" />
              </div>
              <Text type="p" weight="bold">
                {data.category}
              </Text>
            </CategoryText>
          </CategoryWrapper>

          <div className="flex flex-col justify-between h-[70vh] lg:h-[72vh] gap-6 mt-6">
            <MessageWrapper>
              <Text type="p">{data.description}</Text>
            </MessageWrapper>

            <Button
              size="md"
              bgcolor={africredColors.primary.gold[500]}
              color="black"
            >
              <ReplyLink href={`mailto:${data.email}`}>Reply message</ReplyLink>
            </Button>
          </div>
        </div>
      </Wrapper>
      <CloseButton onClick={() => setOpen(false)}>
        <Icon type="close" />
      </CloseButton>
    </Modal>
  );
};
