import React from "react";
import styled from "styled-components";
import { EmailAndPhoneNumber, Modal } from "../../";
import { HorizontalLine, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import profileImage from "../../../../assets/pngs/profile-avatar.png";
import { africredColors } from "../../../../theme/colors";
// import logo from "../../../../assets/svgs/full_logo.svg";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 576px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const InfoWrapper = styled.div`
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const ProfileImageWrapper = styled.img`
  width: 76px;
  height: 76px;
  padding: 0.2rem;
`;
const CurrentStage = styled.div`
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${africredColors.primary.green[200]};
`;

export const PartnerDetailsModal = ({ open, setOpen, data }) => {
  // const [success, setSuccess] = useState(false);

  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        <div display="flex w-full">
          <Text
            className="w-5/6 mt-3"
            type="h3"
            textWrap="wrap"
            weight="medium"
          >
            Details
          </Text>

          <div className="flex flex-col gap-6 mt-6">
            <InfoWrapper>
              <div className="flex gap-4 items-center">
                <ProfileImageWrapper src={profileImage} alt="profile" />
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <Text type="h4">{`${data.firstName} ${data.lastName}`}</Text>
                    <CurrentStage>
                      <Text>{data.accountType}</Text>
                    </CurrentStage>
                  </div>

                  <EmailAndPhoneNumber
                    email={data?.email}
                    phoneNumber={`${data.phoneNumber.code}${data.phoneNumber.number}`}
                  />
                </div>
              </div>
            </InfoWrapper>

            <HorizontalLine />

            <div className="flex flex-col gap-4">
              <Text type="p" weight="bold">
                Organization details
              </Text>
              <div className="grid grid-cols-1 gap-4">
                <InfoWrapper>
                  <Text type="h5">Name</Text>
                  <Text type="p">{data.companyName}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Web URL</Text>
                  <Text type="p">{data.companyWebsite}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Phone</Text>
                  <Text type="p">
                    {data.companyPhoneNumber.code}
                    {data.companyPhoneNumber.number}
                  </Text>
                </InfoWrapper>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InfoWrapper>
                  <Text type="h5">City</Text>
                  <Text type="p">{data.address.city}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Postal/Zip code</Text>
                  <Text type="p">{data.address.zip}</Text>
                </InfoWrapper>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <InfoWrapper>
                  <Text type="h5">State</Text>
                  <Text type="p">{data.address.state}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Country</Text>
                  <Text type="p">{data.address.country}</Text>
                </InfoWrapper>
              </div>
            </div>
            <HorizontalLine />
          </div>
        </div>
      </Wrapper>
      <CloseButton onClick={() => setOpen(false)}>
        <Icon type="close" />
      </CloseButton>
    </Modal>
  );
};
