import React, { useState } from "react";
import styled from "styled-components";
import { CustomRadioInput, Modal } from "../../";
import { Button, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import { africredColors } from "../../../../theme/colors";
import { Link } from "react-router-dom";
import logo from "../../../../assets/svgs/full_logo.svg";
import { camelCaseToSeparateWords } from "../../../../utils";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 576px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const StageInfoWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  width: fit-content;
  background: ${africredColors.primary.green[50]};
`;
const CurrentStage = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: ${africredColors.primary.green[100]};
`;

export const ManageStageModal = ({
  open,
  setOpen,
  title,
  subtitle,
  data,
  updateURL,
}) => {
  const [success, setSuccess] = useState(false);
  const [currentStage, setCurrentStage] = useState(data.status);
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();

  async function updateStage(id, status) {
    try {
      const response = await axiosPrivate.patch(`${updateURL}/${id}`, {
        status,
      });
      if (response.data) {
        toast.success("Status updated!");
        setSuccess(true);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        {!success ? (
          <div display="flex w-full">
            <Text
              className="w-5/6 mt-4"
              type="h3"
              textWrap="wrap"
              weight="medium"
            >
              {title}
            </Text>

            <Text className="w-5/6 mt-3" type="p">
              {subtitle}
            </Text>

            <div className="mb-8 mt-4">
              <StageInfoWrapper>
                <Text>Current stage:</Text>
                <CurrentStage>
                  <Text>{camelCaseToSeparateWords(data.status)}</Text>
                </CurrentStage>
              </StageInfoWrapper>
            </div>

            <div className="flex flex-col gap-6">
              {auth.enumValues.statusEnum.map((item, idx) => (
                <CustomRadioInput
                  value={item}
                  height="auto"
                  name="accountType"
                  checked={currentStage === item}
                  onChange={(e) => setCurrentStage(item)}
                />
              ))}
              <Button
                onClick={() => updateStage(data.id, currentStage)}
                width="100%"
                size="md"
                bgcolor={africredColors.primary.gold[500]}
                color="white"
              >
                Update stage
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex h-[95vh] justify-center items-center">
            <div className="flex flex-col gap-4 items-center">
              <img src={logo} alt="logo" />
              <Text
                className="w-5/6 mt-3"
                type="h3"
                textWrap="wrap"
                weight="medium"
                textalign="center"
              >
                Updated successfully
              </Text>
              <Text
                className="w-5/6 mt-4 mb-4"
                type="p"
                textalign="center"
                textWrap="wrap"
              >
                Lorem ipsum dolor sit amet consectetur. Aliquam eget malesuada
                malesuada nullam congue non volutpat. Consequat nec sit tortor
                sit. Ultrices id fames.
              </Text>
              <div className="flex justify-center">
                <Link to="/">
                  <Button
                    width="300px"
                    size="md"
                    bgcolor={africredColors.primary.gold[500]}
                    color="white"
                  >
                    Back to dashboard
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </Wrapper>
      <CloseButton onClick={() => setOpen(false)}>
        <Icon type="close" />
      </CloseButton>
    </Modal>
  );
};
