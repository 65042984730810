import React, { useRef } from "react";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Icon, Text } from "../../atoms";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DragAndDropWrapper = styled.div`
  display: flex;
  padding: 23px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: ${({ background }) => background};
  border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
  gap: 24px;
  border-radius: 16px;
  border: 1px dashed ${africredColors.primary.green[600]};
  cursor: pointer;
`;
const ProfilePicUploadInput = styled.input`
  width: 0;
  visibility: hidden;
`;
const Loader = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid ${africredColors.neutrals.night[200]};
  border-bottom: 1.5px solid ${africredColors.primary.green[600]};
  background: #fff;
  padding: 1rem;
  margin-top: 5px;
`;

export const DragAndDropFiles = ({ supportedFormats, file, setFile, _acceptedFormats, handleUpload }) => {
  const ref = useRef(null);
  const axiosPrivate = useAxiosPrivate();

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function processFile (selected) {
    if (handleUpload) {
      handleUpload(selected);
    } else {
      const response = handlePhotoInput(selected);
      toast.promise(response, {
        pending: "Uploading image",
        success: "Upload successful 👌",
        error: "Upload failed 🤯",
      });
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const acceptedFormats =  _acceptedFormats || ["image/jpeg", "image/png", "application/pdf"];
    const isValidFiles = acceptedFormats.includes(e.dataTransfer.files[0].type);

    if (!isValidFiles) {
      toast.error("Invalid file format attempted");
      return null;
    }

    processFile(e.dataTransfer.files[0]);
  }

  async function handlePhotoInput(fileData) {
    setFile({ name: fileData.name });
    const imageFormData = new FormData();
    imageFormData.append("file", fileData);
    //TODO: Disable next button
    const response = await axiosPrivate.post(
      "/file/upload-document",
      imageFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    if (response.data) {
      setFile(response.data);
      // TODO: Enable next button
    } else {
      setFile(null);
    }
  }

  return (
    <>
      <DragAndDropWrapper
        onClick={() => ref?.current?.click?.()}
        onDragOver={handleDragOver}
        onDrop={handleFileDrop}
        background={africredColors.neutrals.night[80]}
      >
        <div className="flex gap-4">
          <Icon type="drag-drop" />
          <div className="flex flex-col gap-3">
            <Text type="p">Drag & drop file or Browse</Text>
            <Text type="small">
              {`Supported formates: ${supportedFormats}`}
            </Text>
          </div>
        </div>
      </DragAndDropWrapper>
      {(file?.name || file?.Path) && (
        <Loader>
          <Text type="p"> {file.name || file?.Path} </Text>
          <Icon type="close-filled" />
        </Loader>
      )}
      <ProfilePicUploadInput
        ref={ref}
        type="file"
        accept={(_acceptedFormats?.length) ? _acceptedFormats.join(",") : "application/pdf,image/jpeg,image/png"}
        onChange={(e) => {
          processFile(e.target.files[0]);
        }}
      />
    </>
  );
};
