import React from "react";
import { Icon, Text } from "../../atoms";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";

const PaginationSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PaginationNavigationButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${africredColors.neutrals.night[300]};
  padding: 12px;
`;

export const Pagination = () => {
  return (
    <PaginationSection>
      <Text type="p">1 - 7 of 20</Text>
      <div className="flex gap-6">
        <PaginationNavigationButton>
          <Icon type="back" />
        </PaginationNavigationButton>
        <PaginationNavigationButton>
          <Icon type="next" />
        </PaginationNavigationButton>
      </div>
    </PaginationSection>
  );
};
