import React from "react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";

const TimeWrapper = styled.div`
  padding: 0 0 32px 24px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    border-left: 2px solid ${africredColors.primary.green[600]};
  }

  > .time-icon {
    position: absolute;
    top: 0;
    left: -13px;
    border-radius: 50%;
    padding: 5px;
  }

  > .title {
    margin-top: -2px;
  }
`;
const IconWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || "white"};
`;

export const TimelineIndicator = ({ data }) => {
  return (
    <>
      {data.map((item, idx) => (
        <TimeWrapper key={idx}>
          <Text type="h5" className="title">
            {item.title}
          </Text>
          {item.content}
          <IconWrapper
            bgcolor={
              item?.completed
                ? africredColors.primary.green[50]
                : africredColors.neutrals.night[80]
            }
            className="time-icon"
          >
            {item.icon}
          </IconWrapper>
        </TimeWrapper>
      ))}
    </>
  );
};
