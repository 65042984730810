import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { Button, Input, PasswordInput, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { brandTheme, screen } from "../../../theme";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formatDistance, subDays } from "date-fns";
import useAuth from "../../../hooks/useAuth";

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: flex;
  padding: 48px 12px;
  gap: px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
  height: fit-content;
  justify-content: center;
  align-items: center;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const Heading = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${africredColors.neutrals.night[200]};
`;

export const LoginAndSecuritySettings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const auth = useAuth();

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError("Password and confirm password input mismatch!");
      return null;
    }
    try {
      const response = await axiosPrivate.post("/admin/update-password", {
        currentPassword,
        password: newPassword,
        confirmPassword,
      });

      if (response.data) {
        toast.success("Password changed successfully");
        navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      toast.error(err.response?.data.message);
    }
  };

  return (
    <Wrapper {...pageAnimationConfig}>
      <Text type="h3" weight="medium">
        Login & security
      </Text>
      <WhiteArea>
        <div className="flex flex-col gap-4 w-full sm:w-1/2 2xl:w-1/3">
          <Heading>
            <Text type="h4" weight="medium">
              Login
            </Text>
            {error ? (
              <Text
                type="p"
                className="mt-4"
                color={africredColors.secondary.red[400]}
              >
                {error}
              </Text>
            ) : (
              <Text type="p" className="mt-4">
                Last updated{" "}
                {formatDistance(
                  subDays(new Date(auth.updatedAt), 0),
                  new Date(),
                  { addSuffix: true }
                )}
              </Text>
            )}
          </Heading>

          <Input
            type="text"
            size="md"
            placeholder="Old password"
            autocomplete="off"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />

          <PasswordInput
            size="md"
            placeholder="New password"
            autocomplete="off"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />

          <PasswordInput
            size="md"
            placeholder="Confirm new password"
            autocomplete="off"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <Button
            onClick={handlePasswordChange}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.primary}
          >
            Reset password
          </Button>
        </div>
      </WhiteArea>
    </Wrapper>
  );
};
