import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
    AccessDenied,
    Analytics,
    Dashboard,
    ForgotPassword,
    KYCSettings,
    Loans,
    Login,
    LoginAndSecuritySettings,
    ManageAdmins,
    Notifications,
    OnboardStudent,
    PageNotFound,
    PreferenceSettings,
    PricingSetup,
    ProfileSettingsPage,
    ProofOfFunds,
    ResetPassword,
    Transactions,
    Users,
    SmeLoans,
} from './components/pages'
import { DashboardTemplate } from './components/templates/DashboardTemplate'
import { AnimatePresence } from 'framer-motion'
import { Support } from './components/pages/Support'
import RequireAuth from './components/utils/RequireAuth'
import { UpdateProfileContact } from './components/pages/UpdateProfileContact'
import { ErrorBoundary } from './components/organisms'
import { Universities } from './components/pages/Universities';
import { CreateUniversity } from './components/pages/CreateUniversity';
import { UploadUniveristy } from './components/pages/UploadUniversity';
import { Withdrawals } from './components/pages/Withdrawals';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <RequireAuth>
                <DashboardTemplate />
            </RequireAuth>
        ),
        children: [
            {
                path: '/',
                element: <Dashboard />,
            },
            {
                path: '/analytics',
                element: <Analytics />,
            },
            {
                path: '/loans',
                element: <Loans />,
            },
            {
                path: '/sme-loans/personal',
                element: <SmeLoans loanType="Personal Loan" />,
            },
            {
                path: '/sme-loans/business',
                element: <SmeLoans loanType="Business Loan" />,
            },
            {
                path: '/transactions',
                element: <Transactions />,
            },
            {
                path: '/proof-of-funds',
                element: <ProofOfFunds />,
            },
            {
                path: '/manage-admin',
                element: (
                    <RequireAuth>
                        <ManageAdmins />
                    </RequireAuth>
                ),
            },
            {
                path: '/users',
                element: (
                    <RequireAuth>
                        <Users />
                    </RequireAuth>
                ),
            },
            {
                path: '/withdrawals',
                element: (
                    <RequireAuth>
                        <Withdrawals />
                    </RequireAuth>
                ),
            },
            {
                path: '/universities',
                element: (
                    <RequireAuth>
                        <Universities />
                    </RequireAuth>
                ),
            },
            {
                path: '/settings/profile',
                element: <ProfileSettingsPage />,
            },
            {
                path: '/settings/kyc',
                element: <KYCSettings />,
            },
            {
                path: '/settings/login-security',
                element: <LoginAndSecuritySettings />,
            },
            {
                path: '/settings/preference',
                element: <PreferenceSettings />,
            },
            {
                path: '/settings/pricing',
                element: <PricingSetup />,
            },
            {
                path: '/support',
                errorElement: <ErrorBoundary />,
                element: (
                    <RequireAuth>
                        <Support />
                    </RequireAuth>
                ),
            },
        ],
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/onboard-student',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth>
                <OnboardStudent />
            </RequireAuth>
        ),
    },
    {
        path: '/create-university',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth>
                <CreateUniversity />
            </RequireAuth>
        ),
    },
    {
        path: '/upload-university',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth>
                <UploadUniveristy />
            </RequireAuth>
        ),
    },
    {
        path: '/update-contact/:type',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth>
                <UpdateProfileContact />
            </RequireAuth>
        ),
    },
    {
        path: '/login',
        errorElement: <ErrorBoundary />,
        element: <Login />,
    },
    {
        path: '/forgot-password',
        errorElement: <ErrorBoundary />,
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        errorElement: <ErrorBoundary />,
        element: <ResetPassword />,
    },
    {
        path: '/notifications',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth>
                <Notifications />
            </RequireAuth>
        ),
    },
    {
        path: '/unauthorized',
        errorElement: <ErrorBoundary />,
        element: <AccessDenied />,
    },
    {
        path: '*',
        errorElement: <ErrorBoundary />,
        element: <PageNotFound />,
    },
])

const App = () => {
    return (
        <AnimatePresence mode="wait">
            <RouterProvider router={router} />
        </AnimatePresence>
    )
}

export default App
