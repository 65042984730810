import { Link } from "react-router-dom";
import styled from "styled-components";

export const ClickAbleLabel = styled(Link)`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  display: flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 48px;
  min-width: 143px;
`;
