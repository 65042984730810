import { brandTheme, screen } from "../../../theme";
import { Button, HorizontalLine, Icon, Text } from "../../atoms";
import logo from "../../../assets/svgs/full_logo.svg";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const Wrapper = styled(motion.main)`
  background-color: ${brandTheme.brand.backgroundSkin};
  height: 100vh;
  padding: 120px 12px;
`;
const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 16px;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`;

export const AuthTemplate = ({ title, subtitle, socialAuth, children }) => {
  async function triggerAppleAuth() {
    try {
      await axios.get("/account/apple-auth");
    } catch (err) {
      toast.error("An error occured! Please try email authentication");
    }
  }

  async function triggerGoogleAuth() {
    try {
      await axios.get("/account/google-auth");
    } catch (err) {
      toast.error("An error occured! Please try email authentication");
    }
  }

  return (
    <Wrapper
      {...pageAnimationConfig}
      className="h-screen flex flex-col gap-y-8 items-center justify-center p-4"
    >
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>

      <FormSection className="flex flex-col items-center w-full sm:w-2/3 lg:w-2/4 xl:w-1/3 gap-4 rounded">
        <TitleWrapper>
          <Text textalign="center" type="h2">
            {title}
          </Text>
          {subtitle && (
            <Text textalign="center" type="p">
              {subtitle}
            </Text>
          )}
        </TitleWrapper>

        {socialAuth && (
          <>
            <Button
              onClick={triggerGoogleAuth}
              width="100%"
              size="md"
              borderRadius="16px"
              border={`1px solid ${brandTheme.text.secondary}`}
            >
              <Icon type="google" /> {title} with Google
            </Button>
            <Button
              onClick={triggerAppleAuth}
              width="100%"
              size="md"
              borderRadius="16px"
              border={`1px solid ${brandTheme.text.secondary}`}
            >
              <Icon type="apple" /> {title} with Apple
            </Button>

            <HorizontalLine />
          </>
        )}

        {children}
      </FormSection>
    </Wrapper>
  );
};
