import React, { useState, useEffect } from 'react'
import { Text } from '../../atoms/Text'
import { Icon } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Table } from '../../molecules'
import { Link } from 'react-router-dom'
import profileImage from '../../../assets/pngs/dp.png'
import { pageAnimationConfig, filterSupport } from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useQuery } from '@tanstack/react-query'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { SuccessModal } from '../../molecules/Modal/SuccessModal'
import { SupportMessageModal } from '../../molecules/Modal/SupportMessageModal'
import { TableFilter } from '../../organisms/TableFilter'
import useAuth from '../../../hooks/useAuth'
import { useGridApiRef } from '@mui/x-data-grid'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const ClickAbleLabel = styled(Link)`
    background-color: ${africredColors.neutrals.night[80]};
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 48px;
`
const Flex = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Support = () => {
    const auth = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: 'Category',
    })
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [messageDetails, setMessageDetails] = useState(null)
    const [actionSuccess, setActionSuccess] = useState(null)
    const apiRef = useGridApiRef()

    const {
        isPending,
        error,
        data: supportMessages,
        refetch,
    } = useQuery({
        queryKey: ['support-messages'],
        queryFn: async () => {
            const response = await axiosPrivate.get(
                `/contact/search?${
                    filterData.searchTerm
                        ? `searchTerm=${filterData.searchTerm}`
                        : ''
                }`,
            )
            return response.data.foundContacts.map(item => {
                return {
                    id: item.id,
                    category: item.supportCategory || '',
                    avatar: item.account?.profilePicture?.Location || '',
                    email: item.account?.email || '',
                    issuedBy: `${item.account?.firstName || ''} ${
                        item.account?.lastName || ''
                    }`,
                    description: item.message,
                    date: format(new Date(item.date), 'dd MMM, yyyy'),
                    status: item.issueStatus,
                }
            })
        },
    })

    async function closeIssue(id) {
        try {
            const response = await axiosPrivate.patch(`/contact/manage/${id}`)

            if (response.data) {
                setActionSuccess(true)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1.5,
            minWidth: 220,
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'description',
            headerName: 'Description',
            minWidth: 200,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <ClickAbleLabel
                        onClick={() => {
                            setMessageDetails(row)
                            setOpenDetailsModal(true)
                        }}
                        type="p"
                        color={africredColors.primary.gold[700]}
                    >
                        <Text type="p" weight="bold">
                            View message
                        </Text>
                    </ClickAbleLabel>
                )
            },
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="Close issue"
                        showInMenu
                        onClick={() => {
                            closeIssue(params.row.id)
                        }}
                    />,
                ],
            ],
        },
    ]

    useEffect(() => {
        if (!isPending) {
            refetch()
        }
    }, [filterData.searchTerm, filterData.dateRange])

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    return (
        <Wrapper {...pageAnimationConfig}>
            <div className="flex flex-col gap-2">
                <Text type="h3" weight="medium">
                    Support
                </Text>
                <Text type="p" weight="normal">
                    All support requests. Don’t forget to close cases that have
                    been concluded.
                </Text>
            </div>
            <ContentWrapper>
                <div className="flex justify-between gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showSort
                        showsCsvButton
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="Pro U Support"
                        sortOptions={
                            auth?.enumValues?.supportCategoryEnum || []
                        }
                    />
                </div>

                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={columns}
                        rows={[...supportMessages].filter(
                            filterSupport(filterData),
                        )}
                        pageSize="8"
                    />
                </TableWrapper>
            </ContentWrapper>
            {actionSuccess && (
                <SuccessModal
                    title="Issue closed"
                    subTitle="A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets."
                    ctaText="Go back to support"
                    openModal={actionSuccess}
                    bgcolor={africredColors.primary.gold[500]}
                    color="black"
                />
            )}
            {messageDetails && (
                <SupportMessageModal
                    open={openDetailsModal}
                    setOpen={setOpenDetailsModal}
                    data={messageDetails}
                />
            )}
        </Wrapper>
    )
}
