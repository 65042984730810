import React from "react";
import styled from "styled-components";
import { Modal } from "../../";
import { Icon } from "../../../atoms";
import { brandTheme } from "../../../../theme";
import logo from "../../../../assets/svgs/full_logo.svg";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 412px;
  padding: 24px 18px;
  background-color: ${brandTheme.brand.backgroundSkin};
  position: relative;
  overflow-y: auto;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 26px;
  top: 32px;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileSideNav = ({ isOpen, setIsOpen, children }) => {
  return (
    <Modal isOpen={isOpen} positionX="start">
      <Wrapper>
        <LogoWrapper>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </LogoWrapper>

        {children}

        <CloseButton
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icon type="close" />
        </CloseButton>
      </Wrapper>
    </Modal>
  );
};
