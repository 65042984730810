import { Button, FreeSelect, Icon, Input, SelectInput, Text } from "../../atoms";
import {
  CustomRadioInput,
  DragAndDropFiles,
  InputWithAddonSelect,
  OnboardingFlow,
  fetchFlag,
} from "../../molecules";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";
import { AuthTemplate, CloseablePageTemplage, StudentOnboardTemplate } from "../../templates";
import { africredColors } from "../../../theme/colors";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthContext from "../../../store/contexts/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import logo from "../../../assets/svgs/full_logo.svg";
import profileImg from "../../../assets/svgs/uni_logo.svg";
import success_logo from "../../../assets/svgs/success_logo.svg";
import { motion, progress } from "framer-motion";
import { CreateUniversityTemplate } from "../../templates/CreateUniversityTemplate";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { Tag } from "antd";
import { toast } from "react-toastify";
import { CountryDropdown } from "../../molecules/CountryDropdown";
import CountryList from "../../../assets/json/countries.json";
import RichInput, { RichTextBlankInput } from "../../atoms/RichInput";

const FormSection = styled.section`
    background-color: ${brandTheme.brand.backgroundSkin4};
    border-radius: 16px;
    padding: 48px 24px;
    height: fit-content;

    @media only screen and (${screen.md}) {
        padding: 48px;
    }
`
const ProfilePictureRow = styled.div`
  display: flex;
  gap: 24px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  
  @media only screen and (${screen.md}) {
    align-items: center;
    margin-bottom: 32px;
  }
`;
const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  min-height: 100vh;
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  @media only screen and (${screen.md}) {
    padding-bottom: 0;
  }
`;
const CustomTag = styled(Tag)`
  padding: 12px;
`
const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 1rem;
`;
const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: ${brandTheme.brand.backgroundSkin};

  @media only screen and (${screen.md}) {
    position: static;
  }
`;

const ProfilePicUploadInput = styled.input`
  width: 0;
  visibility: hidden;
`;
const FinalWrapper = styled(motion.main)`
  background-color: ${brandTheme.brand.backgroundSkin};
  height: 100vh;
  padding: 120px 12px;
`;
const FinalFormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 16px;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const FinalTitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`;

const _loanAvailable = "Loan available";
const visaServices = "Visa services";

const StepFooter = ({ goBack, goToNext, className, text }) => {
  return (
    <div
        className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
            className || 'lg:w-2/4 2xl:w-2/5'
        }`}
    >
      <Button
          onClick={goBack && goBack}
          width="fit-content"
          size="md"
          border={`1px solid ${africredColors.neutrals.night[950]}`}
      >
          <Text type="p" weight="medium">
              Back
          </Text>
      </Button>
      <Button
        onClick={() => goToNext({ id: 1 })}
        width="40%"
        size="md"
        bgcolor={brandTheme.brand.primary}
      >
        <Text type="p" weight="bold" color="black">
          {text || "Continue"}
        </Text>
      </Button>
    </div>
  );
};

export const CreateUniversity = () => {
  const [onboardingData, setOnboardingData] = useState({
    initLoad: false,
    location: RichTextBlankInput,
    about: RichTextBlankInput,
    description: RichTextBlankInput,
    website: "",
    loanAvailable: null,
    visaService: null,
    schoolName: "",
    employability: null,
    programCost: {
      minimumAmount: null,
      maximumAmount: null,
    },
    programs: [],
    courses: [],
    livingCost: {
      minimumAmount: null,
      maximumAmount: null,
    },
    address: {
      country: "",
      state: "",
      city: "",
      zip: "",
    },
    banner: null,
    id: "",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [stepCount, setStepCount] = useState(null);
  const [university, setUniversity] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const uni = localStorage.getItem("university");
    if (uni) {
      try {
        const data = JSON.parse(uni);
        setUniversity(data)
      } catch (e) {
        console.warn(e)
      }
    }
  }, [])

  useEffect(() => {
    if (university) {
      const { 
        locationDescription, 
        schoolName, 
        website, 
        state, 
        country, 
        background, 
        icon, 
        about,
        universityDescription,
        programs,
        courses,
        opportunities,
        employability,
        livingCostRange,
        programFeesRange,
        id
      } = university;

      let programCostMinimum = null;
      let programCostMaximum = null;
      let livingCostMinimum = null;
      let livingCostMaximum = null;

      let loanAvailable = null;
      let visaService = null;
      try {
        const splitLiving = (livingCostRange + "").trim().split(" ");
        if (splitLiving.length === 5) {
          // 15k usd to 5k usd
          livingCostMinimum = Number(splitLiving[0].slice(0, splitLiving[0].length - 1)) * 1000;
          livingCostMaximum = Number(splitLiving[3].slice(0, splitLiving[3].length - 1)) * 1000;
        } else {
          // $15 to $30k
          livingCostMinimum = Number(splitLiving[0].slice(0, splitLiving[0].length - 1)) * 1000;
          livingCostMaximum = Number(splitLiving[2].slice(0, splitLiving[2].length - 1)) * 1000;
        }

        const splitProgram = (programFeesRange + "").trim().split(" ");
        if (splitProgram.length === 5) {
          // 15k usd to 5k usd
          programCostMinimum = Number(splitProgram[0].slice(0, splitProgram[0].length - 1)) * 1000;
          programCostMaximum = Number(splitProgram[3].slice(0, splitProgram[3].length - 1)) * 1000;
        } else {
          // $15 to $30k
          programCostMinimum = Number(splitProgram[0].slice(0, splitProgram[0].length - 1)) * 1000;
          programCostMaximum = Number(splitProgram[2].slice(0, splitProgram[2].length - 1)) * 1000;
        }

        loanAvailable = opportunities.find((one) => (one === _loanAvailable) || (one === "Loan availability")) ? "Yes" : "No";
        visaService = opportunities.find((one) => (one === visaServices) || (one === visaServices)) ? "Yes" : "No";
      } catch {

      }

      setOnboardingData({
        location: locationDescription, 
        initLoad: true,
        schoolName, 
        website, 
        background: background?.id, 
        icon, 
        about,
        loanAvailable,
        visaService,
        employability,
        programCost: {
          minimumAmount: programCostMinimum,
          maximumAmount: programCostMaximum,
        },
        livingCost: {
          minimumAmount: livingCostMinimum,
          maximumAmount: livingCostMaximum,
        },
        address: {
          state: state?.name || state, 
          country: country?.name || country, 
          city: "",
          zip: "",
        },
        description: universityDescription,
        programs,
        courses,
        opportunities,
        id,
        banner: background,
      })
    }
  }, [university])

  const goBack = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
  }

  const onNext = async (stepData, numberOfSteps) => {
    setOnboardingData((prev) => ({ ...prev, ...stepData }));
    if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1);
  };
  
  const onFinish = async (stepData) => {
    const opportunities = [];
    if (stepData.loanAvailable === "Yes") opportunities.push(_loanAvailable);
    if (stepData.visaService === "Yes") opportunities.push(visaServices);
    const data = {
      schoolName: stepData.schoolName,
      website: stepData.website,
      state: stepData.address.state,
      country: stepData.address.country,
      about: stepData.about,
      universityDescription: stepData.description,
      locationDescription: stepData.location,
      opportunities,
      programFeesRange: `${parseInt(Number(stepData.programCost.minimumAmount) / 1000)}k USD to ${parseInt(Number(stepData.programCost.maximumAmount) / 1000)}k USD`,
      livingCostRange: `${parseInt(Number(stepData.livingCost.minimumAmount) / 1000)}k USD to ${parseInt(Number(stepData.livingCost.maximumAmount) / 1000)}k USD`,
      programs: stepData.programs,
      courses: stepData.courses,
      employability: Number(stepData.employability),
      icon: stepData.icon,
      background: stepData.background,
    };

    if (!university) {
      try {
        const response = await axiosPrivate.post("/university/create", data);

        if (response.data) {
          onNext(stepData, 4);
        }
      } catch (e) {
        toast.error(e?.response?.data.message)
      }
    } else {
      try {
        const response = await axiosPrivate.patch("/university/update/"+ university.id, data);

        if (response.data) {
          toast.success("Update successful!")
          navigate(-1)
        }
      } catch (e) {
        toast.error(e?.response?.data.message)
      }
    }
  };

  return (
    <CloseablePageTemplage
      onClick={() => {
        localStorage.removeItem("university")
      }}
    >
      <OnboardingFlow
        currentIndex={currentIndex}
        onFinish={onFinish}
        onNext={onNext}
        getStepCount={setStepCount}
        goBack={goBack}
      >
        <StepOne data={onboardingData} setData={setOnboardingData} navigate={navigate} university={university}/>
        <StepTwo data={onboardingData} setData={setOnboardingData} university={university}/>
        <StepThree data={onboardingData} setData={setOnboardingData} university={university}/>
        <StepFour data={onboardingData} setData={setOnboardingData} university={university}/>
      </OnboardingFlow>
    </CloseablePageTemplage>
  );
};

function StepOne({ goToNext, data, goBack, setData, navigate, university }) {
  const auth = useAuth();
  const isMobile = useScreenSize();
  const inputImageRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const facePhoto = university?.icon?.Location || profileImg;
  const [photo, setPhoto] = useState(facePhoto);

  const listOfCountries = useMemo(() => {
    return CountryList.map(one => one.countryName);
  }, [CountryList])

  const listOfStates = useMemo(() => {
    const countrySelected = CountryList.find(one => one.countryName === data.address.country);
    return countrySelected?.regions.map(one => one.name) || []
  }, [CountryList, data.address.country])
  
  useEffect(() => {
    if (data.initLoad) {
      const toUpdate = {}
      if (university?.icon?.Location) {
        setPhoto(university.icon)
        toUpdate.initLoad = false;
      }
      if (university?.background?.Location) {
        toUpdate.initLoad = false;
        toUpdate.banner = university.background;
        toUpdate.background = university.background.id;
      }
      setData(prev => ({
        ...prev,
        ...toUpdate,
      }))
    }
  }, [university, data.initLoad])

  async function handlePhotoInput(fileData) {
    setPhoto({ name: fileData.name });
    const imageFormData = new FormData();
    imageFormData.append("file", fileData);
    //TODO: Disable next button
    const response = await axiosPrivate.post(
      "/file/upload-document",
      imageFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    if (response.data) {
      setPhoto(response.data);
      // TODO: Enable next button
    } else {
      setPhoto(null);
    }
  }

  useEffect(() => {
    if (photo?.Location) {
      setData(prev => ({
        ...prev,
        icon: photo?.id
      }));
    }
  }, [photo])

  return (
    <>
      <CreateUniversityTemplate text={university && "Edit College or University"}>
        <div>
          <div className="flex items-enter">
            <ProfilePictureRow className="flex-col md:flex-row">
              <div className="flex-1">
                <img src={photo?.Location || photo} alt="profile-img" />
              </div>
              <div style={{ flex: 3 }}>
                <div className="flex items-center justify-start">
                  <Button
                    size="sm"
                    border={`1px solid ${africredColors.neutrals.night[300]}`}
                    onClick={() => inputImageRef?.current?.click?.()}
                    borderRadius={"12px"}
                  >
                    <Icon type="upload" />
                    Display Avatar
                  </Button>
                  {/* <Button
                    size="sm"
                    border={`1px solid ${africredColors.neutrals.night[950]}`}
                    onClick={() => inputImageRef?.current?.click?.()}
                  >
                    {!isMobile && <Icon type="image" />}
                      Delete
                  </Button> */}
                </div>
                <Text className="mt-3">
                  It's recommended to use your actual pictures that's at least 98 x 98 pixels and 4MB or less.
                  Use a PNG or GIF (no animations) file.
                </Text>
              </div>
              <ProfilePicUploadInput
                ref={inputImageRef}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const response = handlePhotoInput(e.target.files[0]);
                  toast.promise(response, {
                    pending: "Uploading image",
                    success: "Upload successful 👌",
                    error: "Upload failed 🤯",
                  });
                }}
              />
            </ProfilePictureRow>
          </div>
          <Text
              textalign="left"
              type="h4"
              weight="medium"
              margin="0 0 1rem 0"
          >
              Header photo
          </Text>
          <Text>
            It's recommended to use your actual pictures that's at least 98 x 98 pixels and 4MB or less.
            Use a PNG or GIF (no animations) file.
          </Text>
          <br />
          <DragAndDropFiles
            supportedFormats="JPEG, PNG & PDF"
            file={data.banner}
            setFile={(value) => {
              setData((prev) => ({
                ...prev,
                background: value?.id,
                banner: prev.banner
                  ? Object.assign(prev.banner, value)
                  : value,
              }))
            }}
          />
          <FormRow style={{ marginTop: 0 }}>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  College or University Name
              </Text>
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="Enter Name"
                autoComplete="off"
                value={data.schoolName}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    schoolName: e.target.value,
                  }));
                }}
              />
            </div>
          </FormRow>

          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Country/ Region
              </Text>
              <SelectInput
                options={listOfCountries || []}
                value={data.address.country || "Select"}
                setValue={(val) =>
                  setData((prev) => ({
                    ...prev,
                    address: { ...prev.address, country: val },
                  }))
                }
                size="md"
              />
            </div>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  City/State
              </Text>
              <FreeSelect
                options={listOfStates || []}
                value={data.address.state}
                setValue={(val) =>
                  setData((prev) => ({
                    ...prev,
                    address: { ...prev.address, state: val },
                  }))
                }
                size="md"
              />
            </div>
          </FormRow>
        </div>
      </CreateUniversityTemplate>
      <StepFooter 
        goBack={() => {
          localStorage.removeItem("university");
          navigate("/universities");
        }} 
        goToNext={() => {
          if (
              data.schoolName &&
              data.address.country &&
              data.address.state
          ) {
              goToNext(data)
          } else {
              toast.error(
                  'Please provide all details required!',
              )
          }
        }} 
      />
    </>
  );
}
export const StepTwo = ({ data, goToNext, goBack, setData, university }) => {
  const auth = useAuth();
  const [value, setValue] = useState("");
  const [course, setCourse] = useState("");

  return (
    <>
      <CreateUniversityTemplate text={university && "Edit College or University"}>
        <div>
          <br />
          <Text
              textalign="left"
              type="h5"
              weight="medium"
              margin="0 0 1rem 0"
          >
              Program Fees (in USD)
          </Text>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                size="md"
                padding="12px"
                placeholder="Minimum Amount"
                autoComplete="off"
                value={data.programCost.minimumAmount}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    programCost: { ...prev.programCost, minimumAmount: e.target.value },
                  }));
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                size="md"
                padding="12px"
                placeholder="Maximum Amount"
                autoComplete="off"
                value={data.programCost.maximumAmount}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    programCost: { ...prev.programCost, maximumAmount: e.target.value },
                  }));
                }}
              />
            </div>
          </FormRow>
          <Text
              textalign="left"
              type="h5"
              weight="medium"
              margin="1rem 0 1rem 0"
          >
              Programs
          </Text>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="Engineering"
                autoComplete="off"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
              />
              <div className="flex items-center justify-between mt-2">
                <Text style={{ color: africredColors.neutrals.night[500] }}>Min. of 4 required</Text>
                <Text style={{ color: africredColors.neutrals.night[500] }}>{`${value.length}/50`}</Text>
              </div>
            </div>
            <div>
              <Button
                border={`1px solid ${africredColors.neutrals.night[950]}`}
                onClick={() => {
                  if (value) {
                    setData((prev) => ({
                      ...prev,
                      programs: [...data.programs, value],
                    }));
                    setValue("")
                  }
                }}
                style={{
                  backgroundColor: africredColors.neutrals.night[950],
                  // paddingTop: "6px",
                  // paddingBottom: "6px",
                  // paddingLeft: "19px",
                  // paddingRight: "19px",
                  padding: 16
                }}
              >
                <Icon type="add" color="white"/>
                {/* <Text style={{ fontSize: "30px", fontWeight: "light" }}>+</Text> */}
              </Button>
            </div>
          </FormRow>
          <FormRow>
            <div className="flex wrap flex-wrap">
            {
              data.programs.map((one, index) => (
                <Tag 
                  closeIcon 
                  onClose={() => {
                    const programs = data.programs.filter((_, i) => i != index)
                    setData((prev) => ({
                      ...prev,
                      programs
                    }));
                  }}
                  style={{ 
                    display: "flex", 
                    gap: 4,
                    marginBottom: 12,
                    borderRadius: 12,
                    backgroundColor: brandTheme.brand.backgroundSkin4 
                  }} 
                  className="py-2 px-2"
                >
                  <Text>{one}</Text>
                </Tag>
              ))
            }
            </div>
          </FormRow>
          <Text
              textalign="left"
              type="h5"
              weight="medium"
              margin="1rem 0 1rem 0"
          >
              Courses
          </Text>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="Engineering"
                autoComplete="off"
                value={course}
                onChange={(e) => {
                  setCourse(e.target.value)
                }}
              />
              <div className="flex items-center justify-between mt-2">
                <Text style={{ color: africredColors.neutrals.night[500] }}>Min. of 4 required</Text>
                {/* <Text style={{ color: africredColors.neutrals.night[500] }}>{`${value.length}/50`}</Text> */}
              </div>
            </div>
            <div>
              <Button
                border={`1px solid ${africredColors.neutrals.night[950]}`}
                onClick={() => {
                  if (course) {
                    setData((prev) => ({
                      ...prev,
                      courses: [...data.courses, course],
                    }));
                    setCourse("")
                  }
                }}
                style={{
                  backgroundColor: africredColors.neutrals.night[950],
                  // paddingTop: "6px",
                  // paddingBottom: "6px",
                  // paddingLeft: "19px",
                  // paddingRight: "19px",
                  padding: 16
                }}
              >
                <Icon type="add" color="white"/>
                {/* <Text style={{ fontSize: "30px", fontWeight: "light" }}>+</Text> */}
              </Button>
            </div>
          </FormRow>
          <FormRow>
            <div className="flex wrap flex-wrap">
            {
              data.courses.map((one, index) => (
                <Tag 
                  closeIcon 
                  onClose={() => {
                    const courses = data.courses.filter((_, i) => i != index)
                    setData((prev) => ({
                      ...prev,
                      courses
                    }));
                  }}
                  style={{ 
                    display: "flex", 
                    gap: 4,
                    marginBottom: 12,
                    borderRadius: 12,
                    backgroundColor: brandTheme.brand.backgroundSkin4 
                  }} 
                  className="py-2 px-2"
                >
                  <Text>{one}</Text>
                </Tag>
              ))
            }
            </div>
          </FormRow>
          <Text
              textalign="left"
              type="h5"
              weight="medium"
              margin="1rem 0 1rem 0"
          >
              Living Cost (in USD)
          </Text>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                size="md"
                padding="12px"
                placeholder="Minimum Amount"
                autoComplete="off"
                value={data.livingCost.minimumAmount}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    livingCost: { ...prev.livingCost, minimumAmount: e.target.value },
                  }));
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                size="md"
                padding="12px"
                placeholder="Maximum Amount"
                autoComplete="off"
                value={data.livingCost.maximumAmount}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    livingCost: { ...prev.livingCost, maximumAmount: e.target.value },
                  }));
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Loan Available
              </Text>
              <SelectInput
                options={["Yes", "No"]}
                value={data.loanAvailable}
                setValue={(val) =>
                  setData((prev) => ({
                    ...prev,
                    loanAvailable: val,
                  }))
                }
                size="md"
              />
            </div>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Visa Service
              </Text>
              <SelectInput
                options={["Yes", "No"]}
                value={data.visaService}
                setValue={(val) =>
                  setData((prev) => ({
                    ...prev,
                    visaService: val,
                  }))
                }
                size="md"
              />
            </div>
          </FormRow>
        </div>
      </CreateUniversityTemplate>
      <StepFooter 
        goBack={goBack} 
        goToNext={() => {
          if (
              data.programCost.minimumAmount &&
              data.programCost.maximumAmount &&
              data.programs.length >= 4 &&
              data.courses.length >= 4 &&
              data.livingCost.minimumAmount &&
              data.livingCost.maximumAmount &&
              data.visaService &&
              data.loanAvailable
          ) {
              goToNext(data)
          } else {
              toast.error(
                  'Please provide all details required!',
              )
          }
        }} 
      />
    </>
  );
};

export const StepThree = ({ data, onFinish, goBack, setData, goToNext, university }) => {
  const auth = useAuth();

  return (
    <>
      <CreateUniversityTemplate>
        <div>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Website
              </Text>
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="Enter link"
                autoComplete="off"
                value={data.website}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    website: e.target.value,
                  }));
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Employability
              </Text>
              <Input
                type="number"
                size="md"
                padding="12px"
                icon="%"
                placeholder="80"
                iconposition="right"
                autoComplete="off"
                value={data.employability}
                onChange={(e) => {
                  if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0) {
                    setData((prev) => ({
                      ...prev,
                      employability: e.target.value,
                    }));
                  }
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  University Description
              </Text>
              <RichInput 
                value={data.description}
                setValue={(value) => {
                  setData((prev) => ({
                    ...prev,
                    description: value,
                  }));
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  About University
              </Text>
              <RichInput 
                value={data.about}
                setValue={(value) => {
                  setData((prev) => ({
                    ...prev,
                    about: value,
                  }));
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div style={{ flex: 1 }}>
              <Text
                  textalign="left"
                  type="h5"
                  weight="medium"
                  margin="0 0 1rem 0"
              >
                  Location Description
              </Text>
              <RichInput 
                value={data.location}
                setValue={(value) => {
                  setData((prev) => ({
                    ...prev,
                    location: value,
                  }));
                }}
              />
            </div>
          </FormRow>
        </div>
      </CreateUniversityTemplate>
      <StepFooter 
        goBack={goBack} 
        goToNext={() => {
          if (
              data.description &&
              data.location &&
              data.about &&
              data.website &&
              data.employability
          ) {
              onFinish(data)
          } else {
              toast.error(
                  'Please provide all details required!',
              )
          }
        }} 
        text={university ? "Update University": "Create University"}
      />
    </>
  );
};


export const StepFour = ({ data, onFinish, goBack, setData }) => {

  return (
    <>
      <FinalWrapper
        className="h-screen flex flex-col gap-y-8 items-center justify-center p-4"
      >
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>

        <FinalFormSection className="flex flex-col items-center w-full sm:w-2/3 lg:w-3/5 gap-4 rounded">
          <img src={success_logo} alt="logo" />
          <FinalTitleWrapper>
            <Text textalign="center" type="h2">
              Created successfully
            </Text>
            <Text textalign="center" type="p">
              A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets.
            </Text>
          </FinalTitleWrapper>
          <Link to="/">
              <Button
                  width="100%"
                  size="md"
                  bgcolor={brandTheme.brand.primary}
              >
                  Back to dashboard
              </Button>
          </Link>
        </FinalFormSection>
      </FinalWrapper>
    </>
  )
}