import { styled } from 'styled-components'
import { inputSizes } from '../../../theme/typeScale'
import { Icon } from '../Icon'

const StyledButton = styled.button`
    border-radius: ${({ borderRadius }) => borderRadius || '48px'};
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ color }) => color};
    border: ${({ border }) => border || 'none'};
    width: ${({ width }) => width};
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: ${({ padding }) => `${padding} !important` || 'none'};
`

const { lg, md, sm, xs } = inputSizes

export const Button = ({ size, children, loading, ...rest }) => {
    const renderContent = () => {
        if (loading) {
            return (
                <div class="flex gap-1 items-center">
                    <div class="w-[1.3em] h-[1.3em]">
                        <Icon type="spinner" />
                    </div>
                    Loading
                </div>
            )
        }
        return children
    }

    if (size === 'lg') {
        return (
            <StyledButton {...rest} style={lg}>
                {renderContent()}
            </StyledButton>
        )
    } else if (size === 'md') {
        return (
            <StyledButton {...rest} style={md}>
                {renderContent()}
            </StyledButton>
        )
    } else if (size === 'sm') {
        return (
            <StyledButton {...rest} style={sm}>
                {renderContent()}
            </StyledButton>
        )
    } else if (size === 'xs') {
        return (
            <StyledButton {...rest} style={xs}>
                {renderContent()}
            </StyledButton>
        )
    }
    return <StyledButton {...rest}>{renderContent()}</StyledButton>
}
