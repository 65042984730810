import styled, { css } from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Icon } from '../../atoms/Icon'
import { Text } from '../../atoms/Text'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { motion } from 'framer-motion'
import { pageAnimationConfig } from '../../../utils'
import profileImage from '../../../assets/pngs/dp.png'
import { Table } from '../../molecules'
import { screen } from '../../../theme'
import { SelectInput } from '../../atoms'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { format } from 'date-fns'
import { NoRecordFound } from '../../molecules/NoRecordFound'

const Wrapper = styled(motion.div)`
    display: flex;
    gap: 24px;
    flex-direction: column;
`

const TableWrapper = styled.div`
    width: 100%;
    color: black;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

const highlightStyles = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0 36px 0;
    gap: 16px;
    border-radius: 16px;
    background: transparent;
    width: 100%;

    > div {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        padding: 12px;
    }

    p {
        text-wrap: nowrap;
    }
`

const Highlight = styled.section`
    ${highlightStyles};

    @media only screen and (${screen.lg}) {
        border-radius: 32px;
        padding: 36px 35px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        background: ${africredColors.neutrals.white[50]};

        > div {
            flex-direction: row;
            align-items: center;
            padding: 24px;
        }
    }
`

const HighlightBox = styled.div`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
`

const RecentTransaction = styled.section`
    padding: 12px;
    align-items: center;
    border-radius: 32px;
    color: black;
    background: ${africredColors.neutrals.white[50]};

    > div:first-child {
        border-bottom: 1px solid ${africredColors.neutrals.night[200]};
    }

    .MuiDataGrid-withBorderColor {
        border-bottom: none !important;
    }

    @media only screen and (${screen.lg}) {
        padding: 36px 35px;
    }
`

const ApplicationStatusArea = styled.section`
    grid-area: quickAction;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    overflow-y: auto;
    width: 100%;
    gap: 16px;

    @media only screen and (${screen.md}) {
        width: 40%;
    }

    @media only screen and (${screen.lg}) {
        width: 30%;
    }
`

const ActivityChart = styled.section`
    width: 100%;
    display: flex;
    padding: 23px 20px;
    flex-direction: column;
    align-items: space-between;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    height: 100%;

    @media only screen and (${screen.md}) {
        width: 60%;
    }

    @media only screen and (${screen.lg}) {
        width: 70%;
    }
`

const ChartWrapper = styled.div`
    height: 100%;
    width: 100%;
`

const Details = styled.div`
    display: flex;
    flex-grow: 2;
    gap: 16px;
    height: auto;
    flex-direction: column;

    @media only screen and (${screen.md}) {
        height: 45vh;
        display: flex;
        flex-direction: row;
    }
`

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

export const Dashboard = () => {
    const axiosPrivate = useAxiosPrivate()
    const [summaryFilterOption, setSummaryFilterOption] = useState('Loan')
    const [statsFilter, setStatsFilter] = useState('Student')
    const [topUsersFilterOption, setTopUsersFilterOption] = useState('Student')
    const {
        isPending,
        error,
        data: dashboardData,
    } = useQuery({
        queryKey: ['dashboard-data'],
        queryFn: async () => {
            const response = await axiosPrivate('/transaction/admin-dashboard')
            return response.data
        },
    })

    const chartStep =
        dashboardData?.graphPointsForEarnings?.earningsTotalAmounts?.reduce(
            (partialSum, a) => partialSum + a,
            0,
        )

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    const columns = [
        {
            field: 'referenceNumber',
            headerName: 'Transaction Id',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 200,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            width="35px"
                            src={profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">
                            {value?.substring(0, 25)}{' '}
                            {value.length > 25 ? '...' : ''}
                        </Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return <Text type="p">{value}</Text>
            },
        },
        {
            field: 'currency',
            headerName: 'Currency',
            renderHeader,
        },
        {
            field: 'purpose',
            headerName: 'Purpose',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
    ]

    const lastesTransactions =
        dashboardData?.transactions?.[
            summaryFilterOption === 'Short term loans'
                ? 'pofTransactions'
                : summaryFilterOption === 'Loan'
                ? 'loanTransactions'
                : 'admissionTransactions'
        ]

    function getBgColor(index) {
        if (index === 0) return africredColors.primary.blue[100]
        if (index === 1) return africredColors.primary.gold[100]
        if (index === 2) return africredColors.secondary.purple[100]
        if (index === 3) return africredColors.primary.green[100]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
        },
        scales: {
            y: {
                ticks: {
                    stepSize: chartStep / 4,
                },
            },
        },
        tension: 0.4,
    }

    const data = {
        labels: dashboardData?.earningInfo?.[
            statsFilter === 'Student'
                ? 'studentGraphPointsForEarnings'
                : 'agentGraphPointsForEarnings'
        ]?.[
            statsFilter === 'Student'
                ? 'studentEarningsMonthNames'
                : 'agentEarningsMonthNames'
        ],
        datasets: [
            {
                label: 'Total',
                data: dashboardData?.earningInfo?.[
                    statsFilter === 'Student'
                        ? 'studentGraphPointsForEarnings'
                        : 'agentGraphPointsForEarnings'
                ]?.[
                    statsFilter === 'Student'
                        ? 'studentEarningsTotalAmounts'
                        : 'agentEarningsTotalAmounts'
                ],
                borderColor: africredColors.neutrals.night[950],
                backgroundcolor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }

    return (
        <Wrapper {...pageAnimationConfig}>
            <Highlight>
                <HighlightCard
                    icon={<Icon type="transaction" />}
                    amount={dashboardData.transactionStats.totalTransactions}
                    text="Total Transactions"
                />
                <HighlightCard
                    icon={<Icon type="wallet" />}
                    amount={dashboardData.transactionStats.totalRemittances}
                    text="Total Payments"
                />
                <HighlightCard
                    icon={<Icon type="card" />}
                    amount={dashboardData.transactionStats.totalLoans}
                    text="Total Loans"
                />
                <HighlightCard
                    icon={<Icon type="agent" />}
                    amount={dashboardData.admissionStats}
                    text="Total Admissions"
                />

                <HighlightCard
                    backgroundcolor={getBgColor(0)}
                    icon={<Icon type="user" />}
                    amount={dashboardData.usersCount.totalNoOfUsers}
                    text="Total Users"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(1)}
                    icon={<Icon type="student" />}
                    amount={dashboardData.usersCount.totalNoStudents}
                    text="Total Students"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(2)}
                    icon={<Icon type="agent" />}
                    amount={dashboardData.usersCount.totalNoAgents}
                    text="Total Agents"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(3)}
                    icon={<Icon type="partner" />}
                    amount={dashboardData.usersCount.totalNoGuardians}
                    text="Total Partners"
                />
            </Highlight>

            <RecentTransaction>
                <div className="flex w-full items-center justify-between pb-4">
                    <div>
                        <Text type="h4">New Applications</Text>
                    </div>
                    <div className="flex items-center gap-4">
                        <SelectInput
                            width="fit-content"
                            options={['Loan', 'Short term loans', 'Admissions']}
                            value={summaryFilterOption}
                            setValue={item => setSummaryFilterOption(item)}
                            size="sm"
                            bgcolor="transparent"
                            border="0"
                        />
                    </div>
                </div>
                {lastesTransactions?.length > 0 ? (
                    <div className="flex justify-between pt-2">
                        <TableWrapper>
                            <Table
                                hidePagination
                                removeBorder
                                columns={columns}
                                rows={lastesTransactions.map(item => ({
                                    ...item,
                                    id: item.id,
                                    referenceNumber: item.referenceNumber
                                        ? item.referenceNumber
                                        : '...',
                                    issuedBy: `${item.firstName} ${item.lastName}`,
                                    amount:
                                        summaryFilterOption ===
                                        'Short term loans'
                                            ? item.pofAmount
                                            : summaryFilterOption === 'Loan'
                                            ? item.loanAmount
                                            : item.pofAmount,
                                    purpose: item.purpose,
                                    date: format(
                                        new Date(item.createdAt),
                                        'dd MMM, yyyy',
                                    ),
                                    status: item.paymentStatus,
                                    currency: item.currency,
                                }))}
                                pageSize="6"
                            />
                        </TableWrapper>
                    </div>
                ) : (
                    <NoRecordFound className="mt-6" />
                )}
            </RecentTransaction>

            <Details>
                <ActivityChart>
                    <div className="flex w-full items-center justify-between">
                        <div>
                            <Text type="h4">Earnings</Text>
                        </div>
                        <div className="flex items-center gap-4">
                            <SelectInput
                                options={['Student', 'Agent']}
                                value={statsFilter}
                                setValue={val => setStatsFilter(val)}
                                size="sm"
                                bgcolor="transparent"
                                border="none"
                                width="fit-content"
                            />
                        </div>
                    </div>

                    <ChartWrapper>
                        <Line options={options} data={data} />
                    </ChartWrapper>
                </ActivityChart>

                <ApplicationStatusArea>
                    <div className="flex justify-between items-center gap-4 mb-4">
                        <Text type="h4">Top Users</Text>
                        <SelectInput
                            options={['Student', 'Agent']}
                            value={topUsersFilterOption}
                            setValue={item => setTopUsersFilterOption(item)}
                            size="sm"
                            bgcolor="transparent"
                            border="none"
                            width="fit-content"
                        />
                    </div>
                    {dashboardData?.topUsers?.[
                        topUsersFilterOption === 'Student'
                            ? 'topStudentUsers'
                            : 'topAgentUsers'
                    ]?.map((item, idx) => (
                        <div
                            key={idx}
                            className="flex justify-between items-center gap-4"
                        >
                            <div className="flex items-center gap-3">
                                <img
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                    }}
                                    src={
                                        item.profilePicture
                                            ? item.profilePicture.Location
                                            : profileImage
                                    }
                                />
                                <div className="flex flex-col gap-2">
                                    <Text type="p" weight="bold">
                                        {item.firstName} {item.lastName}
                                    </Text>
                                    <Text type="p">15th Jan. 2023</Text>
                                </div>
                            </div>
                            <Text type="p" weight="bold">
                                {item.totalTransactionAmount}
                            </Text>
                        </div>
                    ))}
                </ApplicationStatusArea>
            </Details>
        </Wrapper>
    )
}

const HighlightCard = ({ icon, amount, text, backgroundcolor }) => {
    return (
        <HighlightBox backgroundcolor={backgroundcolor}>
            <div>{icon}</div>
            <div>
                <Text type="h3">{amount}</Text>
                <Text type="p">{text}</Text>
            </div>
        </HighlightBox>
    )
}
