import { brandTheme } from '../../../theme'
import { Button, Icon, Input, PasswordInput, Text } from '../../atoms'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { AuthTemplate } from '../../templates/AuthTemplate'
import { useContext, useState } from 'react'
import axios from '../../../api/axios'
import AuthContext from '../../../store/contexts/AuthProvider'
import { africredColors } from '../../../theme/colors'

export const Login = () => {
    const { setAuth } = useContext(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (loading) return;
        
        try {
            setLoading(true)

            const response = await axios.post('/admin/login', {
                email,
                password,
            })

            setLoading(false)

            // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
            if (response.data) {
                setAuth(response.data)

                // navigate to the route from whence the user came or home.
                navigate(from || '/', { replace: true })
            }
        } catch (err) {
            setLoading(false)

            if (err?.response?.status === 401) {
                setError('Invalid credentials. Check email/password!')
            }
        }
    }

    return (
        <AuthTemplate title="Admin Login">
            {error ? (
                <Text type="p" color={africredColors.secondary.red[600]}>
                    {error}
                </Text>
            ) : null}
            <Input
                icon={<Icon type="email" />}
                type="text"
                size="md"
                placeholder="Email"
                autoComplete="off"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <PasswordInput
                size="md"
                placeholder="Password"
                autoComplete="off"
                padding="12px"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <div className="w-full">
                <Button
                    loading={loading}
                    onClick={handleSubmit}
                    width="100%"
                    size="md"
                    bgcolor={brandTheme.brand.primary}
                >
                    Login
                </Button>
            </div>

            <div className="flex justify-center w-full">
                <Link to="/forgot-password">
                    <Text type="small">
                        Can&apos;t access your account? Forgot password
                    </Text>
                </Link>
            </div>
        </AuthTemplate>
    )
}
