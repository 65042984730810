import React, { useState } from 'react'
import { Text } from '../../atoms/Text'
import { Icon } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Table } from '../../molecules'
import profileImage from '../../../assets/pngs/dp.png'
import {
    pageAnimationConfig,
    sortWithDate,
    filterUser,
    formatDate,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { UserModal } from '../../molecules/Modal/UserModal'
import { toast } from 'react-toastify'
import { useGridApiRef } from '@mui/x-data-grid'
import axiosPublic from '../../../api/axios'
import useDebounce from '../../utils/useDebounce';

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`
function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Withdrawals = () => {
    const axiosPrivate = useAxiosPrivate()
    const [userDetails, setUserDetails] = useState(null)
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        loanStatus: '',
        dateRange: [],
    })
    const apiRef = useGridApiRef()
    const searchTerm = useDebounce(filterData.searchTerm, 500);

    const {
        isPending,
        error,
        data: users,
        refetch,
    } = useQuery({
        queryKey: [
            'get-withdrawals',
            filterData.dateRange &&
                filterData.dateRange[0]?.format('YYYY-MM-DD'),
            filterData.dateRange &&
                filterData.dateRange[1]?.format('YYYY-MM-DD'),
            filterData.loanStatus,
            searchTerm,
        ],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/payout/search?${
                    filterData.loanStatus
                    ? `status[0]=${filterData.loanStatus}`
                    : ''
                }${
                    filterData.dateRange?.length
                        ? `&startDate=${filterData.dateRange[0].format(
                              'YYYY-MM-DD',
                          )}&endDate=${filterData.dateRange[1].format(
                              'YYYY-MM-DD',
                          )}`
                        : ''
                }${ searchTerm ? "&searchTerm="+ searchTerm: ""}`,
            )

            return response.data?.foundPayoutRequests?.map(item => ({
                id: item.id,
                avatar: item.account?.profilePicture?.Location,
                fullName: `${item.account?.firstName || ''} ${item.account?.lastName || ''}`,
                email: item.account?.email || '',
                phone: `${item.account?.phoneNumber?.code || ''} ${
                    item.account?.phoneNumber?.number || ''
                }`,
                accountType: item.account?.accountType,
                gender: item.account?.gender,
                location: `${item.account?.address?.state || ''}, ${
                    item.account?.address?.country || ''
                }`,
                createdAt: item.createdAt,
                date: item.createdAt,
                amount: item.amountToWithdrawInCurrency,
                amountInUsd: item.amountInUsd,
                country: item.country,
                bank: item.bankName,
                accountNumber: item.accountNumber,
                narration: item.fullName,
                status: item.status
            }))
        },
    })

    const { isMobile } = useScreenSize()

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    async function completeRequest(id) {
        try {
            const response = await axiosPrivate.patch(
                `/payout/update/${id}`,
            )
            if (response.data) {
                toast.success(
                    `Status updated successfully`,
                )
                refetch()
            }
        } catch (err) {
            toast.error(err.response?.data?.message)
        }
    }

    async function cancelRequest(id) {
        try {
            const response = await axiosPrivate.patch(
                `/payout/cancel/${id}`,
            )
            if (response.data) {
                toast.success(
                    `Status updated successfully`,
                )
                refetch()
            }
        } catch (err) {
            toast.error(err.response?.data?.message)
        }
    }

    const columns = [
        {
            field: 'fullName',
            headerName: 'Full name',
            minWidth: 250,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amountInUsd',
            headerName: 'Amount (USD)',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'country',
            headerName: 'Local Currency',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'bank',
            headerName: 'Bank',
            minWidth: 250,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'accountNumber',
            headerName: 'Account Number',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        // {
        //     field: 'location',
        //     headerName: 'Location',
        //     minWidth: 110,
        //     flex: 1.5,
        //     renderHeader,
        // },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => 
                params.row.status === "Pending" ?
                [
                    <GridActionsCellItem
                        label={
                            "Flag as Completed"
                        }
                        showInMenu
                        onClick={() => completeRequest(params.row.id)}
                    />,
                    <GridActionsCellItem
                        label={
                            "Cancel"
                        }
                        showInMenu
                        onClick={() => cancelRequest(params.row.id)}
                    />,
                ] : [],
        },
    ]

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    Withdrawal Requests
                </Text>
                <Text type="p" textWrap="nowrap">
                    All agents withdrawal requests
                </Text>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showDateRange
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        showLoanStatus
                        loanStatusOptions={["Pending", "Completed", "Cancelled"]}
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={users
                            .sort(sortWithDate(filterData))
                            .filter(one => one)}
                        pageSize="8"
                    />
                </TableWrapper>
            </ContentWrapper>

            {userDetails && (
                <UserModal
                    title="Details"
                    open={openDetailsModal}
                    setOpen={setOpenDetailsModal}
                    data={userDetails}
                />
            )}
        </Wrapper>
    )
}
