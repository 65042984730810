import React from "react";
import { africredColors } from "../../../theme/colors";
import styled, { css } from "styled-components";
import { Button, Icon, Text } from "../../atoms";
import { useQuery } from "@tanstack/react-query";
import { axiosPrivate } from "../../../api/axios";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { NoRecordFound } from "../NoRecordFound";
import { Loader } from "../../atoms/Loader";

const dropdownItemStyles = css`
  display: flex;
  padding: 16px 0px;

  gap: 24px;
  background: ${africredColors.neutrals.white[10]};

  &:not(:last-child) {
    border-bottom: 1px solid ${africredColors.neutrals.night[300]};
  }
`;
const NotificationItem = styled.div`
  align-items: flex-start;
  ${dropdownItemStyles}
`;
const NotificationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${africredColors.neutrals.night[50]};
`;

export const NotificationDropdown = () => {
  const {
    isPending,
    error,
    data: notifications,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const response = await axiosPrivate("/notification/view-all");
      return response.data;
    },
  });

  if (isPending) return <Loader type="circle" />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {notifications?.length > 0 ? (
        <>
          <div>
            {notifications?.slice(0, 2)?.map((item) => (
              <NotificationItem key={item}>
                <div>
                  <NotificationLogoWrapper>
                    <Icon type="logo" />
                  </NotificationLogoWrapper>
                </div>
                <div>
                  <Text type="p">{item.message}</Text>
                  <div className="flex gap-4">
                    <Text margin="5px 0 0 0" type="small">
                      {format(new Date(item.createdAt), "dd MMM, yyyy")}
                    </Text>
                  </div>
                </div>
              </NotificationItem>
            ))}
          </div>
          <Button
            width="100%"
            size="md"
            border={`1px solid ${africredColors.neutrals.night[950]}`}
          >
            <Link to="/notifications">
              <Text type="p" weight="medium">
                View all notification
              </Text>
            </Link>
          </Button>
        </>
      ) : (
        <NoRecordFound message="No notification yet..." className="h-[30px]" />
      )}
    </>
  );
};
