import { Button, Icon, Input, SelectInput, Text } from "../../atoms";
import {
  CustomRadioInput,
  InputWithAddonSelect,
  OnboardingFlow,
  fetchFlag,
} from "../../molecules";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";
import { StudentOnboardTemplate } from "../../templates";
import { africredColors } from "../../../theme/colors";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthContext from "../../../store/contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  min-height: 100vh;
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  @media only screen and (${screen.md}) {
    padding-bottom: 0;
  }
`;
const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;
const FooterWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${africredColors.neutrals.night[200]};
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: ${brandTheme.brand.backgroundSkin};

  @media only screen and (${screen.md}) {
    position: static;
  }
`;
const StepFooter = ({ goToNext, className }) => {
  return (
    <FooterWrapper
      className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
        className || "lg:w-2/4 xl:w-2/5"
      }`}
    >
      <Button
        onClick={() => goToNext({ id: 1 })}
        width="40%"
        size="md"
        bgcolor={brandTheme.brand.primary}
      >
        <Text type="p" weight="bold" color="black">
          Continue
        </Text>
      </Button>
    </FooterWrapper>
  );
};

export const OnboardStudent = () => {
  const [onboardingData, setOnboardingData] = useState({
    accountType: "Agent",
    useCase: "Short term loans",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: {
      code: "+234",
      number: "",
    },
    address: {
      country: "",
      state: "",
      city: "",
      zip: "",
    },
    maritalStatus: "",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [stepCount, setStepCount] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.kycStatus) {
      navigate("/", { replace: true });
    }
  }, [auth.kycStatus, navigate]);

  const onNext = async (stepData, numberOfSteps) => {
    await setOnboardingData((prev) => ({ ...prev, ...stepData }));
    if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1);
  };
  const onFinish = async (stepData) => {
    try {
      const response = await axiosPrivate.patch("/account/kyc", stepData);

      // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
      if (response.data) {
        setAuth(response.data);

        // navigate to the route from whence the user came or home.
        navigate("/");
      }
    } catch (err) {}
  };

  return (
    <Wrapper>
      <OnboardingFlow
        currentIndex={currentIndex}
        onFinish={onFinish}
        onNext={onNext}
        getStepCount={setStepCount}
      >
        <StepOne data={onboardingData} setData={setOnboardingData} />
        <StepTwo data={onboardingData} setData={setOnboardingData} />
      </OnboardingFlow>
    </Wrapper>
  );
};

function StepOne({ goToNext, data, goBack, setData }) {
  return (
    <>
      <StudentOnboardTemplate className="w-full md:w-4/6">
        <div>
          <Text type="h5" className="mb-5">
            How do you want to be identified?
          </Text>
          <div className="flex flex-col md:flex-row gap-4 mb-8">
            <CustomRadioInput
              value="Student"
              name="accountType"
              checked={data.accountType === "Student"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Student",
                }))
              }
              description="a person who is studying at a university or other place of higher
        education."
            />
            <CustomRadioInput
              value="Guardian"
              name="accountType"
              checked={data.accountType === "Guardian"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Guardian",
                }))
              }
              description="A parent or someone with legal responsibility to care for a child or an adult who need self-care"
            />
            <CustomRadioInput
              value="Agent"
              name="accountType"
              checked={data.accountType === "Agent"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Agent",
                }))
              }
              description="a person who is studying at a university or other place of higher education."
            />
          </div>

          <Text type="h5" className="mb-5">
            How do you want to be identified?
          </Text>

          <div className="w-full flex flex-col gap-4 mb-4">
            <CustomRadioInput
              height="100px"
              value="Short term loans"
              name="useCase"
              checked={data.useCase === "Short term loans"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  useCase: "Short term loans",
                }))
              }
              description="This a document that demonstrates that a person or entity has the complete funds needed for a specific transaction or request."
            />
            <CustomRadioInput
              height="100px"
              value="Send money/fees abroad"
              name="useCase"
              checked={data.useCase === "Send money/fees abroad"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  useCase: "Send money/fees abroad",
                }))
              }
              description="This a document that demonstrates that a person or entity has the complete funds needed for a specific transaction or request."
            />
          </div>
        </div>
      </StudentOnboardTemplate>
      <StepFooter goToNext={() => goToNext(data)} />
    </>
  );
}
export const StepTwo = ({ data, onFinish, goBack, setData }) => {
  const auth = useAuth();

  return (
    <>
      <StudentOnboardTemplate>
        <FormInputs>
          <FormRow>
            <Input
              icon={<Icon type="person" />}
              type="text"
              size="md"
              placeholder="First Name"
              autoComplete="off"
              value={data.firstName}
              onChange={(e) => {
                setData((prev) => ({ ...prev, firstName: e.target.value }));
              }}
            />
            <Input
              icon={<Icon type="person" />}
              type="text"
              size="md"
              placeholder="Last Name"
              autoComplete="off"
              value={data.lastName}
              onChange={(e) => {
                setData((prev) => ({ ...prev, lastName: e.target.value }));
              }}
            />
          </FormRow>

          <InputWithAddonSelect
            inputType="tel"
            setInputValue={(e) => {
              setData((prev) => ({
                ...prev,
                phoneNumber: { ...prev.phoneNumber, number: e.target.value },
              }));
            }}
            inputValue={data.phoneNumber.number}
            padding="10px 16px"
            placeholder="000 000 0000"
            selectMenuData={[
              {
                icon: fetchFlag("USD"),
                value: "+1",
              },
              {
                icon: fetchFlag("NGN"),
                value: "+234",
              },
            ]}
            borderradius="34px"
          />

          <FormRow>
            <SelectInput
              options={["Male", "Female", "Not Specified"]}
              value={data.gender || "Gender"}
              setValue={(val) => setData((prev) => ({ ...prev, gender: val }))}
              size="md"
            />
          </FormRow>

          <FormRow>
            <SelectInput
              options={["Married", "Single", "Divorced", "Separated"]}
              value={data.maritalStatus || "Marital Status"}
              setValue={(val) =>
                setData((prev) => ({ ...prev, maritalStatus: val }))
              }
              size="md"
            />
          </FormRow>

          <FormRow>
            <Input
              type="text"
              size="md"
              padding="12px"
              placeholder="City"
              autoComplete="off"
              value={data.address.city}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, city: e.target.value },
                }));
              }}
            />
            <Input
              type="text"
              size="md"
              padding="12px"
              placeholder="Postal/Zip Codes"
              autoComplete="off"
              value={data.address.zip}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, zip: e.target.value },
                }));
              }}
            />
          </FormRow>

          <Input
            type="text"
            size="md"
            padding="12px"
            placeholder="State"
            autoComplete="off"
            value={data.address.state}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                address: { ...prev.address, state: e.target.value },
              }));
            }}
          />

          <SelectInput
            options={auth?.enumValues?.countryEnum || []}
            value={data.address.country || "Country"}
            setValue={(val) =>
              setData((prev) => ({
                ...prev,
                address: { ...prev.address, country: val },
              }))
            }
            size="md"
          />
        </FormInputs>
      </StudentOnboardTemplate>
      <StepFooter goToNext={() => onFinish(data)} />
    </>
  );
};
