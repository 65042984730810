import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';
import { africredColors } from '../../../theme/colors';


const styles = css`
    width: ${({ width }) => width || '100%'};
    background-color: ${({ bgcolor }) =>
        bgcolor || africredColors.neutrals.night['80']};
    border-radius: ${({ borderRadius }) => borderRadius || '2rem'};
    gap: 1rem;

    &:focus {
      border: 0.5px solid ${africredColors.neutrals.night[800]};
    }
    
    .ql-container {
      border: none;
    } 
    .ql-toolbar {
      border: none;
      border-bottom: 1px solid #ccc;
    }
`

const Container = styled(ReactQuill)`
  ${styles}
`;

const Wrapper = styled.div`
`;

export const RichTextBlankInput = "<p><br></p><p><br></p><p><br></p>";

export default function RichInput({
  value,
  setValue
}) {
  return <Wrapper>
    <Container 
      theme="snow" 
      value={value} 
      onChange={setValue} 
    />
  </Wrapper>
}