import React, { useEffect, useMemo, useState } from 'react'
import { Text } from '../../atoms/Text'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Button, Input, Icon } from '../../atoms'
import { Table } from '../../molecules'
import profileImage from '../../../assets/pngs/dp.png'
import {
    camelCaseToSeparateWords,
    filterLoan,
    getLabelBgColor,
    getLabelColor,
    pageAnimationConfig,
    sortWithDate,
    formatDate,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { RequestInfoModal } from '../../molecules/Modal/RequestInfoModal'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { RequestDetailsModal } from '../../molecules/Modal/RequestDetailsModal'
// import { PartnerDetailsModal } from "../../molecules/Modal/PartnerDetailsModal";
import { ManageStageModal } from '../../molecules/Modal/ManageStageModal'
import { ClickAbleLabel } from '../../atoms/ClickableLabel'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { PartnerDetailsModal } from '../../molecules/Modal/PartnerDetailsModal'
import { RequestProgressModal } from '../../molecules/Modal/RequestProgressModal'
import { SuccessModal } from '../../molecules/Modal/SuccessModal'
import { useGridApiRef } from '@mui/x-data-grid'
import axiosPublic from '../../../api/axios'
import useDebounce from '../../utils/useDebounce'
import { useSearchParams } from 'react-router-dom'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const Flex = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Loans = () => {
    const axiosPrivate = useAxiosPrivate()
    const [transactionDetails, setTransactionDetails] = useState(null)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [params] = useSearchParams()
    const searchedId = params.get('id')
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        loanStatus: '',
        dateRange: [],
        page: 1,
        pageSize: 50,
        export: false,
    })
    const searchTerm = useDebounce(filterData.searchTerm, 700)
    const apiRef = useGridApiRef()

    const { isPending: genericsDataPending, data: genericsData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const response = await axiosPublic(`/generic-apis/all-enum`)

            if (response.data) {
                return response.data
            }
        },
    })

    const { isPending, error, data, refetch } = useQuery({
        queryKey: [
            'loan',
            searchTerm,
            filterData.dateRange &&
                filterData.dateRange[0]?.format('YYYY-MM-DD'),
            filterData.dateRange &&
                filterData.dateRange[1]?.format('YYYY-MM-DD'),
            filterData.loanStatus,
            filterData.page,
            filterData.pageSize,
        ],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/loan/search?${searchTerm ? `searchTerm=${searchTerm}` : ''}
        ${
            filterData.dateRange?.length
                ? `&startDate=${filterData.dateRange[0].format(
                      'YYYY-MM-DD',
                  )}&endDate=${filterData.dateRange[1].format('YYYY-MM-DD')}`
                : ''
        }
        ${
            filterData.loanStatus ? `&status[0]=${filterData.loanStatus}` : ''
        }&page=${filterData.page}&limit=${filterData.pageSize}`,
            )
            return response.data
        },
    })
    const { isMobile } = useScreenSize()
    const [openModal, setOpenModal] = useState(false)
    const [openCanelModal, setOpenCancelModal] = useState(false)
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [openPartnerDetailsModal, setOpenPartnerDetailsModal] =
        useState(false)
    const [openManageStageModal, setOpenManageStageModal] = useState(false)
    const [progressData, setProgressData] = useState(null)
    const [rejectionSuccessful, setRejectionSuccessful] = useState(false)
    const [rejectionMessage, setRejectionMessage] = useState('')

    const [openRequestDocumentModal, setOpenRequestDocumentModal] = useState({
        stage: null,
        open: false,
    })

    const loans = useMemo(() => {
        return data?.foundLoans.map(item => ({
            ...item,
            id: item.id,
            referenceNumber: item.referenceNumber || '...',
            acceptedBy: item.partner
                ? `${item.partner?.firstName} ${item.partner?.lastName}`
                : '...',
            avatar: item.account?.profilePicture?.Location,
            issuedBy: `${item.firstName} ${item.lastName}`,
            amount: `${item.loanAmount}`,
            purpose: item.purpose,
            date: item.createdAt,
            status: item.status,
            approvalStages: {
                inReviewStage: item.inReviewStage,
                hasEoiStage: item.hasEoiStage,
                loggedInStage: item.loggedInStage,
                approvedStage: item.approvedStage,
                disburseStage: item.disburseStage,
            },
            currency: item.currency,
        }))
    }, [data])

    const rowCount = useMemo(() => {
        return data?.count
    }, [data])

    const onPaginationModelChange = model => {
        setFilterData(prev => ({
            ...prev,
            page: model.page + 1,
            pageSize: model.pageSize,
        }))
    }

    const handleExport = () => {
        setFilterData(prev => ({
            ...prev,
            page: 1,
            pageSize: rowCount,
            export: true,
        }))
    }

    useEffect(() => {
        if (
            filterData.export &&
            !isPending &&
            filterData.pageSize === rowCount
        ) {
            handleExportClick()

            setFilterData(prev => ({
                ...prev,
                page: 1,
                pageSize: 100,
                export: false,
            }))
        }
    }, [filterData.export, isPending, rowCount, filterData.pageSize])

    const handleExportClick = () => {
        if (!apiRef.current) {
            return
        }

        const exportOptions = {
            fileName: `ProU Loans`,
            allColumns: true,
        }

        apiRef.current.exportDataAsCsv(exportOptions)
    }
    useEffect(() => {
        if (searchedId) {
            loadSearchedData(searchedId)
        }
    }, [searchedId])

    async function loadSearchedData() {
        setLoadingFetch(true)
        try {
            const response = await axiosPrivate(
                `/loan/search?id[0]=${searchedId}`,
            )

            if (response.data && response.data.foundLoans?.length) {
                const item = response.data.foundLoans[0]
                setTransactionDetails({
                    ...item,
                    id: item.id,
                    referenceNumber: item.referenceNumber || '...',
                    acceptedBy: item.partner
                        ? `${item.partner?.firstName} ${item.partner?.lastName}`
                        : '...',
                    avatar: item.account?.profilePicture?.Location,
                    issuedBy: `${item.firstName} ${item.lastName}`,
                    amount: `${item.loanAmount}`,
                    purpose: item.purpose,
                    date: item.createdAt,
                    status: item.status,
                    approvalStages: {
                        inReviewStage: item.inReviewStage,
                        hasEoiStage: item.hasEoiStage,
                        loggedInStage: item.loggedInStage,
                        approvedStage: item.approvedStage,
                        disburseStage: item.disburseStage,
                    },
                    currency: item.currency,
                })
                setOpenDetailsModal(true)
            }
        } catch (err) {
            toast.error(err.response?.data?.message || 'Something went wrong')
        }
        setLoadingFetch(false)
    }

    const columns = [
        {
            field: 'referenceNumber',
            headerName: 'Reference ID',
            minWidth: 250,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'acceptedBy',
            headerName: 'Accepted by',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        {value ? (
                            <>
                                <img
                                    style={{
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                    }}
                                    src={row.avatar || profileImage}
                                    alt={`profile${value}`}
                                />
                                <Text type="p">{value}</Text>
                            </>
                        ) : (
                            '...'
                        )}
                    </Flex>
                )
            },
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={row.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">{value}</Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            renderHeader,
        },
        {
            field: 'currency',
            headerName: 'Currency',
            flex: 1,
            renderHeader,
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <ClickAbleLabel
                        color={getLabelColor(value)}
                        backgroundcolor={getLabelBgColor(value)}
                    >
                        {camelCaseToSeparateWords(value)}
                    </ClickAbleLabel>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="Reject"
                        showInMenu
                        onClick={() => {
                            setTransactionDetails(params.row)
                            if (params.row.status !== 'Rejected') {
                                setOpenCancelModal(true)
                            } else {
                                toast.info(
                                    'This request has already been rejected!',
                                )
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        label="Loan Details"
                        showInMenu
                        onClick={async () => {
                            setTransactionDetails(params.row)
                            setOpenDetailsModal(true)
                        }}
                    />,
                    <GridActionsCellItem
                        label="Manage Stage"
                        showInMenu
                        onClick={() => {
                            setTransactionDetails(params.row)
                            setOpenManageStageModal(true)
                        }}
                    />,
                    <GridActionsCellItem
                        label="Track Progress"
                        showInMenu
                        onClick={async () => {
                            await setProgressData(prev => params.row)
                            setOpenModal(true)
                        }}
                    />,
                    <GridActionsCellItem
                        label="Partner details"
                        showInMenu
                        onClick={() => {
                            setTransactionDetails(params.row)
                            setOpenPartnerDetailsModal(true)
                        }}
                    />,
                ],
            ],
        },
    ]

    async function rejectApplication(id, reason, transactionType) {
        try {
            const response = await axiosPrivate.patch(
                `/status-tracker/reject/${id}`,
                {
                    reason,
                    transactionType,
                },
            )
            if (response.data) {
                setRejectionSuccessful(true)
            }
        } catch (error) {
            toast.error('An error occured while sending request!')
        }
    }

    if (isPending || loadingFetch)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    Loans
                </Text>
                <Text type="p" textWrap="nowrap">
                    All loans applications
                </Text>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showLoanStatus
                        showsCsvButton
                        showDateRange
                        filterData={filterData}
                        setFilterData={setFilterData}
                        apiRef={apiRef}
                        csvFileName="ProU Loans"
                        loanStatusOptions={genericsData.statusEnum}
                        handleExport={handleExport}
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={[...loans]}
                        pageSize="100"
                        rowCount={rowCount}
                        page={filterData.page - 1}
                        onPaginationModelChange={onPaginationModelChange}
                    />
                </TableWrapper>
            </ContentWrapper>

            {transactionDetails?.partner && (
                <PartnerDetailsModal
                    title="Details"
                    open={openPartnerDetailsModal}
                    setOpen={setOpenPartnerDetailsModal}
                    data={transactionDetails.partner}
                />
            )}

            {progressData && (
                <RequestProgressModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    progressData={progressData}
                    setOpenRequestDocumentModal={setOpenRequestDocumentModal}
                    openRequestDocumentModal={openRequestDocumentModal}
                    transactionType="loan"
                />
            )}

            {transactionDetails && (
                <>
                    {!rejectionSuccessful ? (
                        <RequestInfoModal
                            open={openCanelModal}
                            setOpen={setOpenCancelModal}
                            title="Reject Application"
                            subtitle="Lorem ipsum dolor sit amet consectetur. Neque porttitor amet sed est in amet. Mauris convallis sed sagittis ornare faucibus."
                        >
                            <div className="h-[78vh] flex flex-col justify-between mt-12">
                                <div>
                                    <Text
                                        type="h5"
                                        className="mb-4"
                                        weight="500"
                                    >
                                        Reason
                                    </Text>
                                    <Input
                                        type="textArea"
                                        borderRadius="1rem"
                                        value={rejectionMessage}
                                        onChange={e =>
                                            setRejectionMessage(e.target.value)
                                        }
                                    />
                                </div>

                                <div>
                                    <Text
                                        type="h4"
                                        className="mb-4"
                                        weight="500"
                                    >
                                        Are you sure?
                                    </Text>
                                    <Text
                                        type="p"
                                        className="mb-4"
                                        weight="500"
                                    >
                                        Lorem ipsum dolor sit amet consectetur.
                                        Neque porttitor amet sed est in amet.
                                        Mauris convallis sed sagittis ornare
                                        faucibus.
                                    </Text>
                                    <Button
                                        onClick={() =>
                                            rejectApplication(
                                                transactionDetails.id,
                                                rejectionMessage,
                                                'Loan',
                                            )
                                        }
                                        width="100%"
                                        size="md"
                                        bgcolor={
                                            africredColors.primary.gold[500]
                                        }
                                    >
                                        Yes, cancel
                                    </Button>
                                </div>
                            </div>
                        </RequestInfoModal>
                    ) : (
                        <SuccessModal
                            openModal={rejectionSuccessful}
                            title="Application Rejected"
                            ctaText="Back to loans"
                            closeable={false}
                            redirectURL="/loans"
                        />
                    )}

                    <RequestDetailsModal
                        title="Details"
                        open={openDetailsModal}
                        setOpen={setOpenDetailsModal}
                        data={transactionDetails}
                    />

                    <ManageStageModal
                        title="Manage Stage"
                        subtitle="Lorem ipsum dolor sit amet consectetur. Neque porttitor amet sed est in amet. Mauris convallis sed sagittis ornare faucibus."
                        open={openManageStageModal}
                        setOpen={setOpenManageStageModal}
                        data={transactionDetails}
                        updateURL="/loan/loan-update-stage"
                    />
                </>
            )}
        </Wrapper>
    )
}
