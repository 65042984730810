import React from "react";
import { Icon, Text } from "../../atoms";

export const EmailAndPhoneNumber = ({ email, phoneNumber }) => {
  return (
    <div className="flex flex-wrap gap-2 items-center">
      <a href={`tel:${phoneNumber}`}>
        <div className="flex gap-2 items-center">
          <div>
            <Icon type="phone" />
          </div>{" "}
          <Text type="p">{phoneNumber}</Text>
        </div>
      </a>
      {
        email && <a href={`mailto:${email}`}>
          <div className="flex gap-2 items-center">
            <div>
              <Icon type="email-sm" />
            </div>
            <Text type="p">{email}</Text>
          </div>
        </a>
      }
    </div>
  );
};
