import React, { useState } from "react";
import { SuccessModal } from "../SuccessModal";
import { africredColors } from "../../../../theme/colors";
import { RequestInfoModal } from "../RequestInfoModal";
import { Button, Input, Text } from "../../../atoms";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import styled from "styled-components";

const ModalButtonBtn = styled(Button)`
  position: absolute;
  left: 16px;
  bottom: 24px;
  width: 95%;
`;

export const RequestDocumentModal = ({
  openModal,
  setOpenModal,
  progressData,
  transactionType,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [successfulAction, setSuccessfulAction] = useState(false);
  const [documentRequestMessage, setDocumentRequestMessage] = useState("");

  async function requestDocumentFromUser(id, stageName, review) {
    try {
      const response = await axiosPrivate.patch(
        `/${
          transactionType === "loan" ? "loan" : "pof"
        }/request-document/${id}`,
        {
          status: stageName,
          review,
        }
      );
      if (response.data) {
        setSuccessfulAction(true);
      }
    } catch (error) {
      toast.error("An error occured while sending request!");
    }
  }

  return (
    <>
      {!successfulAction ? (
        <RequestInfoModal
          open={openModal}
          setOpen={(val) => {
            setOpenModal((prev) => ({
              ...prev,
              open: val,
            }));
          }}
          title="Request Document"
        >
          <div className="h-[78vh] flex flex-col justify-between mt-12">
            <div>
              <Text type="h5" className="mb-4" weight="500">
                Message
              </Text>
              <Input
                type="textArea"
                borderRadius="1rem"
                value={documentRequestMessage}
                onChange={(e) => setDocumentRequestMessage(e.target.value)}
              />
            </div>

            <ModalButtonBtn
              onClick={() =>
                requestDocumentFromUser(
                  progressData.id,
                  progressData.status,
                  documentRequestMessage
                )
              }
              width="100%"
              size="md"
              bgcolor={africredColors.primary.gold[500]}
            >
              Send request
            </ModalButtonBtn>
          </div>
        </RequestInfoModal>
      ) : (
        <SuccessModal
          openModal={successfulAction}
          title="Document requested!"
          subTitle="A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets."
          ctaText={`Back to ${
            transactionType === "loan" ? "Loans" : "Short term loans"
          }`}
        />
      )}
    </>
  );
};
