import {
  Button,
  FreeSelect,
  Icon,
  Input,
  resolveFontWeight,
  SelectInput,
  Text,
} from '../../atoms'
import {
  CustomRadioInput,
  DragAndDropFiles,
  InputWithAddonSelect,
  OnboardingFlow,
  fetchFlag,
} from '../../molecules'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { brandTheme, screen } from '../../../theme'
import {
  AuthTemplate,
  CloseablePageTemplage,
  StudentOnboardTemplate,
} from '../../templates'
import { africredColors } from '../../../theme/colors'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import AuthContext from '../../../store/contexts/AuthProvider'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import logo from '../../../assets/svgs/full_logo.svg'
import profileImg from '../../../assets/svgs/uni_logo.svg'
import success_logo from '../../../assets/svgs/success_logo.svg'
import { motion, progress } from 'framer-motion'
import { UploadUniversityTemplate } from '../../templates/UploadUniversityTemplate'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { Tag } from 'antd'
import { toast } from 'react-toastify'
import { CountryDropdown } from '../../molecules/CountryDropdown'
import CountryList from '../../../assets/json/countries.json'
import RichInput, { RichTextBlankInput } from '../../atoms/RichInput'

const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 24px;
  height: fit-content;

  @media only screen and (${screen.md}) {
      padding: 48px;
  }
`
const ProfilePictureRow = styled.div`
  display: flex;
  gap: 24px;

  img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
  }

  @media only screen and (${screen.md}) {
      align-items: center;
      margin-bottom: 32px;
  }
`
const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  min-height: 100vh;
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  @media only screen and (${screen.md}) {
      padding-bottom: 0;
  }
`
const CustomTag = styled(Tag)`
  padding: 12px;
`
const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`
const FormRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 1rem;
`
const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: ${brandTheme.brand.backgroundSkin};

  @media only screen and (${screen.md}) {
      position: static;
  }
`

const ProfilePicUploadInput = styled.input`
  width: 0;
  visibility: hidden;
`
const FinalWrapper = styled(motion.main)`
  background-color: ${brandTheme.brand.backgroundSkin};
  height: 100vh;
  padding: 120px 12px;
`
const FinalFormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 16px;

  @media only screen and (${screen.md}) {
      padding: 48px;
  }
`
const FinalTitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  .warning {
      margin-right: 20px;
  }
`

const UploadWrapper = styled.div`
  a {
      text-decoration: underline;
      color: ${africredColors.primary.blue[800]};
  }
`;

const _loanAvailable = 'Loan available'
const visaServices = 'Visa services'

const StepFooter = ({ goBack, goToNext, className, text }) => {
  return (
      <div
          className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
              className || 'lg:w-2/4 2xl:w-2/5'
          }`}
      >
          <Button
              onClick={goBack && goBack}
              width="fit-content"
              size="md"
              border={`1px solid ${africredColors.neutrals.night[950]}`}
          >
              <Text type="p" weight="medium">
                  Back
              </Text>
          </Button>
          <Button
              onClick={() => goToNext({ id: 1 })}
              width="40%"
              size="md"
              bgcolor={brandTheme.brand.primary}
          >
              <Text type="p" weight="bold" color="black">
                  {text || 'Continue'}
              </Text>
          </Button>
      </div>
  )
}

export const UploadUniveristy = () => {
  const [onboardingData, setOnboardingData] = useState({
      initLoad: false,
      count: 0,
      entries: [],
      name: null,
      loading: false,
  })

  const [currentIndex, setCurrentIndex] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [stepCount, setStepCount] = useState(null)
  const [university, setUniversity] = useState()
  const axiosPrivate = useAxiosPrivate()
  const { setAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
      const uni = localStorage.getItem('university')
      if (uni) {
          try {
              const data = JSON.parse(uni)
              setUniversity(data)
          } catch (e) {
              console.warn(e)
          }
      }
  }, [])

  const goBack = () => {
      if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
  }

  const onNext = async (stepData, numberOfSteps) => {
      setOnboardingData(prev => ({ ...prev, ...stepData }))
      if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1)
  }

  const onFinish = async stepData => {
      const opportunities = []
      if (stepData.loanAvailable === 'Yes') opportunities.push(_loanAvailable)
      if (stepData.visaService === 'Yes') opportunities.push(visaServices)
      const data = {
          schoolName: stepData.schoolName,
          website: stepData.website,
          state: stepData.address.state,
          country: stepData.address.country,
          about: stepData.about,
          universityDescription: stepData.description,
          locationDescription: stepData.location,
          opportunities,
          programFeesRange: `${parseInt(
              Number(stepData.programCost.minimumAmount) / 1000,
          )}k USD to ${parseInt(
              Number(stepData.programCost.maximumAmount) / 1000,
          )}k USD`,
          livingCostRange: `${parseInt(
              Number(stepData.livingCost.minimumAmount) / 1000,
          )}k USD to ${parseInt(
              Number(stepData.livingCost.maximumAmount) / 1000,
          )}k USD`,
          programs: stepData.programs,
          employability: Number(stepData.employability),
          icon: stepData.icon,
          background: stepData.background,
      }

      if (!university) {
          try {
              const response = await axiosPrivate.post(
                  '/university/create',
                  data,
              )

              if (response.data) {
                  onNext(stepData, 4)
              }
          } catch (e) {
              toast.error(e?.response?.data.message)
          }
      } else {
          try {
              const response = await axiosPrivate.patch(
                  '/university/update/' + university.id,
                  data,
              )

              if (response.data) {
                  toast.success('Update successful!')
                  navigate(-1)
              }
          } catch (e) {
              toast.error(e?.response?.data.message)
          }
      }
  }

  return (
      <CloseablePageTemplage
          onClick={() => {
              localStorage.removeItem('university')
          }}
      >
          <OnboardingFlow
              currentIndex={currentIndex}
              onFinish={onFinish}
              onNext={onNext}
              getStepCount={setStepCount}
              goBack={goBack}
          >
              <StepOne
                  data={onboardingData}
                  setData={setOnboardingData}
                  navigate={navigate}
                  university={university}
              />
              <StepTwo
                  data={onboardingData}
                  setData={setOnboardingData}
                  university={university}
              />
              <StepFour
                  data={onboardingData}
                  setData={setOnboardingData}
                  university={university}
              />
          </OnboardingFlow>
      </CloseablePageTemplage>
  )
}

function StepOne({ goToNext, data, goBack, setData, navigate, university }) {
  const axiosPrivate = useAxiosPrivate()

  async function handleUpload(fileData) {
      const request = async () => {
          setData(prev => ({
              ...prev,
              name: fileData.name,
          }))
          const imageFormData = new FormData()
          imageFormData.append('file', fileData)
          //TODO: Disable next button
          const response = await axiosPrivate
              .post('/university/upload-multiple', imageFormData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      Accept: 'application/json',
                  },
              })
              .catch(err => {
                  setData(prev => ({
                      ...prev,
                      name: null,
                  }))
                  throw err
              })

          if (response.data) {
              setData(prev => ({
                  ...prev,
                  entries: response.data.data,
                  count: response.data.count,
              }))
          }
      }

      toast.promise(request(), {
          pending: 'Verifying file',
          success: 'Verification successful 👌',
          error: 'Verification failed 🤯',
      })
  }

  return (
      <>
          <UploadUniversityTemplate>
              <UploadWrapper>
                  <Text
                      textalign="left"
                      type="h4"
                      weight="medium"
                      margin="0 0 1rem 0"
                  >
                      Upload Excel File
                  </Text>
                  <Text type="p">
                      To download the template guide on how to upload the
                      .xlsx file required, <a href='https://afri-cred.s3.eu-west-2.amazonaws.com/documents/University%20Upload-1724089903710.xlsx' target='_blank'>click here</a>
                  </Text>
                  <br />
                  <DragAndDropFiles
                      handleUpload={handleUpload}
                      supportedFormats=".XLSX"
                      _acceptedFormats={[
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      ]}
                      file={data}
                      setFile={value => {}}
                  />
              </UploadWrapper>
          </UploadUniversityTemplate>
          <StepFooter
              goBack={() => {
                  localStorage.removeItem('university')
                  navigate('/universities')
              }}
              goToNext={() => {
                  if (data.name && data.count) {
                      goToNext(data)
                  } else {
                      toast.error('Please provide all details required!')
                  }
              }}
          />
      </>
  )
}
export const StepTwo = ({ data, goToNext, goBack, setData, university }) => {
  const auth = useAuth()
  const [value, setValue] = useState('')
  const axiosPrivate = useAxiosPrivate()

  const uploadUniversities = async () => {
      const payload = data.entries

      try {
          const response = await axiosPrivate.post(
              '/university/create-multiple',
              payload,
          )

          if (response.data) {
              goToNext(data)
          }
      } catch (e) {
          toast.error(e?.response?.data.message)
      }
  }

  return (
      <>
          <UploadUniversityTemplate>
              <div>
                  <Text
                      textalign="left"
                      type="h4"
                      weight="medium"
                      margin="0 0 1rem 0"
                  >
                      {`${data.count} ${
                          data.count > 1 ? 'Universities' : 'University'
                      } Extracted Successfully`}
                  </Text>
                  <Text type="p" className={'faded-text'}>
                      By clicking the{' '}
                      <span style={{ fontFamily: resolveFontWeight('bold') }}>
                          Confirm & Upload
                      </span>{' '}
                      button, I affirm that the information presented on the
                      excel file has been reviewed and approved to be
                      following the instructed guideline and template
                  </Text>
                  <br />
                  <WarningContainer>
                      <div className="warning">
                          <Icon type="warning" />
                      </div>
                      <Text type={'h5'}>This action can't be reversed!</Text>
                  </WarningContainer>
              </div>
          </UploadUniversityTemplate>
          <StepFooter
              goBack={goBack}
              goToNext={() => {
                  if (true) {
                      uploadUniversities()
                  } else {
                      toast.error('Please provide all details required!')
                  }
              }}
              text={'Confirm & Upload'}
          />
      </>
  )
}

export const StepFour = ({ data, onFinish, goBack, setData }) => {
  const navigate = useNavigate()

  return (
      <>
          <FinalWrapper className="h-screen flex flex-col gap-y-8 items-center justify-center p-4">
              <Link to="/">
                  <img src={logo} alt="logo" />
              </Link>

              <FinalFormSection className="flex flex-col items-center w-full sm:w-2/3 lg:w-3/5 gap-4 rounded">
                  <img src={success_logo} alt="logo" />
                  <FinalTitleWrapper>
                      <Text textalign="center" type="h2">
                          Upload successfully
                      </Text>
                      <Text textalign="center" type="p">
                          Kindly check and be sure every fields are in the
                          proper formats and don't forget to update any
                          missing data manually!
                      </Text>
                  </FinalTitleWrapper>
                  <Button
                      width="100%"
                      size="md"
                      bgcolor={brandTheme.brand.primary}
                      onClick={() => navigate(-1)}
                  >
                      View Universities
                  </Button>
              </FinalFormSection>
          </FinalWrapper>
      </>
  )
}
