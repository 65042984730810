import React from "react";
import { RequestInfoModal } from "../RequestInfoModal";
import { formatNumber } from "../../../../utils";
import { StepProgressIndicator } from "../../StepProgressIndicator";
import { RequestDocumentModal } from "../RequestDocumentModal";

export const RequestProgressModal = ({
  openModal,
  setOpenModal,
  progressData,
  openRequestDocumentModal,
  setOpenRequestDocumentModal,
  transactionType,
}) => {
  return (
    <>
      {openModal && (
        <RequestInfoModal
          open={openModal}
          setOpen={(val) => {
            setOpenModal(val);
          }}
          title={`You have applied for a short term loan of ${
            progressData.currency
          } ${formatNumber(progressData.amount)}`}
          subtitle="Kindly track your loan application process below."
        >
          <div className="mt-8 p-2">
            <StepProgressIndicator
              requestDocument={setOpenRequestDocumentModal}
              data={progressData}
            />
          </div>
        </RequestInfoModal>
      )}

      {openRequestDocumentModal.open && (
        <RequestDocumentModal
          openModal={openRequestDocumentModal.open}
          setOpenModal={setOpenRequestDocumentModal}
          progressData={progressData}
          transactionType={transactionType}
        />
      )}
    </>
  );
};
