import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";
import { Icon } from "../atoms";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
`

const PersistLogin = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const auth = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // persist Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, [auth?.accessToken, refresh]);

  return <>{isLoading ? (
      <Wrapper className="w-full flex items-center justify-center text-gray-400 gap-2">
          <div className="w-10 h-10">
              <Icon type="spinner" />
          </div>
          Loading
      </Wrapper>
  ) : <>{children}</>}</>;
};

export default PersistLogin;
