import React from "react";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button } from "../../atoms";
import { brandTheme } from "../../../theme";
import { Link } from "react-router-dom";

export const ResetPasswordConfirmation = () => {
  return (
    <AuthTemplate
      title="Reset password successfully"
      subtitle="Account password updated please login to get back were you left off!"
    >
      <Link to="/login">
        <Button width="100%" size="md" bgcolor={brandTheme.brand.primary}>
          Proceed to Login
        </Button>
      </Link>
    </AuthTemplate>
  );
};
