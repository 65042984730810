import React from "react";
import styled from "styled-components";
import { Modal } from "../../";
import { Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 576px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;

export const RequestInfoModal = ({
  open,
  setOpen,
  data,
  children,
  title,
  subtitle,
  closeable = true,
}) => {
  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        <div display="flex w-full">
          <Text
            className="w-5/6 mt-4"
            type="h3"
            textWrap="wrap"
            weight="medium"
          >
            {title}
          </Text>

          <Text className="w-5/6 mt-3" type="p">
            {subtitle}
          </Text>
        </div>

        <div>{children}</div>
      </Wrapper>
      {closeable && (
        <CloseButton onClick={() => setOpen(false)}>
          <Icon type="close" />
        </CloseButton>
      )}
    </Modal>
  );
};
