import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { Button, Icon, Input, SelectInput, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { screen } from "../../../theme";
import {
  CustomRadioInput,
  InputWithAddonSelect,
  fetchFlag,
} from "../../molecules";
import useAuth from "../../../hooks/useAuth";

const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: block;
  padding: 48px 16px;
  gap: px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
  height: fit-content;

  @media only screen and (${screen.xl}) {
    display: flex;
    padding: 48px 64px;
  }
`;
const LeftSide = styled.div`
  border-right: 0;

  @media only screen and (${screen.xl}) {
    border-right: 1px solid ${africredColors.neutrals.night[200]};
  }
`;

export const KYCSettings = () => {
  const [data, setData] = useState({
    accountType: "Agent",
    useCase: "Short term loans",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: {
      code: "+234",
      number: "",
    },
    address: {
      country: "",
      state: "",
      city: "",
      zip: "",
    },
    maritalStatus: "",
  });
  const auth = useAuth();

  function handleUpdate() {}

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between items-center">
        <Text type="h3" weight="medium">
          KYC details
        </Text>
        <Button
          onClick={handleUpdate}
          // width="100%"
          size="md"
          // bgcolor={brandTheme.brand.primary}
          border={`1px solid ${africredColors.neutrals.night[400]}`}
        >
          Update changes
        </Button>
      </div>
      <WhiteArea>
        <LeftSide className="w-full md:w-3/4 xl:w-1/2 xl:pr-16">
          <Text type="h4" weight="medium">
            Update KYC details
          </Text>
          <div className="flex items-center gap-4 mt-4 mb-4">
            <Icon
              type="checked-circle"
              width={25}
              height={25}
              stroke={africredColors.primary.green[600]}
            />
            <Text type="p">KYC completed</Text>
          </div>
          <FormInputs>
            <FormRow>
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="First Name"
                autoComplete="off"
                value={data.firstName}
                onChange={(e) => {
                  setData((prev) => ({ ...prev, firstName: e.target.value }));
                }}
              />
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="Last Name"
                autoComplete="off"
                value={data.lastName}
                onChange={(e) => {
                  setData((prev) => ({ ...prev, lastName: e.target.value }));
                }}
              />
            </FormRow>

            <InputWithAddonSelect
              inputType="tel"
              setInputValue={(e) => {
                setData((prev) => ({
                  ...prev,
                  phoneNumber: { ...prev.phoneNumber, number: e.target.value },
                }));
              }}
              inputValue={data.phoneNumber.number}
              padding="10px 16px"
              placeholder="000 000 0000"
              selectMenuData={[
                {
                  icon: fetchFlag("USD"),
                  value: "+1",
                },
                {
                  icon: fetchFlag("NGN"),
                  value: "+234",
                },
              ]}
              borderradius="34px"
            />

            <FormRow>
              <SelectInput
                options={["Male", "Female", "Not Specified"]}
                value={data.gender || "Gender"}
                setValue={(val) =>
                  setData((prev) => ({ ...prev, gender: val }))
                }
                size="md"
              />
            </FormRow>

            <FormRow>
              <SelectInput
                options={["Married", "Single", "Divorced", "Separated"]}
                value={data.maritalStatus || "Marital Status"}
                setValue={(val) =>
                  setData((prev) => ({ ...prev, maritalStatus: val }))
                }
                size="md"
              />
            </FormRow>

            <FormRow>
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="City"
                autoComplete="off"
                value={data.address.city}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    address: { ...prev.address, city: e.target.value },
                  }));
                }}
              />
              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="Postal/Zip Codes"
                autoComplete="off"
                value={data.address.zip}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    address: { ...prev.address, zip: e.target.value },
                  }));
                }}
              />
            </FormRow>

            <Input
              type="text"
              size="md"
              padding="12px"
              placeholder="State"
              autoComplete="off"
              value={data.address.state}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, state: e.target.value },
                }));
              }}
            />

            <SelectInput
              options={auth?.enumValues?.countryEnum || []}
              value={data.address.country || "Country"}
              setValue={(val) =>
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, country: val },
                }))
              }
              size="md"
            />
          </FormInputs>
        </LeftSide>
        <div className="w-full md:w-3/4 xl:w-1/2 w-1/2 mt-8 xl:mt-0 xl:pl-16">
          <Text type="h5" className="mb-5">
            How do you want to be identified?
          </Text>
          <div className="flex flex-col gap-4 mb-8">
            <CustomRadioInput
              value="Student"
              height="100px"
              name="accountType"
              checked={data.accountType === "Student"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Student",
                }))
              }
              description="a person who is studying at a university or other place of higher
        education."
            />
            <CustomRadioInput
              height="100px"
              value="Guardian"
              name="accountType"
              checked={data.accountType === "Guardian"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Guardian",
                }))
              }
              description="A parent or someone with legal responsibility to care for a child or an adult who need self-care"
            />
            <CustomRadioInput
              height="100px"
              value="Agent"
              name="accountType"
              checked={data.accountType === "Agent"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Agent",
                }))
              }
              description="a person who is studying at a university or other place of higher education."
            />
          </div>

          <Text type="h5" className="mb-5">
            How do you want to be identified?
          </Text>

          <div className="w-full flex flex-col gap-4 mb-4">
            <CustomRadioInput
              height="100px"
              value="Short term loans"
              name="useCase"
              checked={data.useCase === "Short term loans"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  useCase: "Short term loans",
                }))
              }
              description="This a document that demonstrates that a person or entity has the complete funds needed for a specific transaction or request."
            />
            <CustomRadioInput
              height="100px"
              value="Send money/fees abroad"
              name="useCase"
              checked={data.useCase === "Send money/fees abroad"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  useCase: "Send money/fees abroad",
                }))
              }
              description="This a document that demonstrates that a person or entity has the complete funds needed for a specific transaction or request."
            />
          </div>
        </div>
      </WhiteArea>
    </Wrapper>
  );
};
