import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosPublic = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  credentials: "include",
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  credentials: "include",
});

axiosPublic.defaults.maxRedirects = 0; // Set to 0 to prevent automatic redirects
axiosPublic.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [301, 302].includes(error.response.status)) {
      const redirectUrl = error.response.headers.location;
      return axiosPublic.get(redirectUrl);
    }
    return Promise.reject(error);
  }
);

export default axiosPublic;
