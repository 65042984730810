import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGridApiRef } from '@mui/x-data-grid'
import { Text } from '../../atoms/Text'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Button, Icon, Input } from '../../atoms'
import { Table } from '../../molecules'
import { pageAnimationConfig, sortWithDate, formatDate } from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { TableFilter } from '../../organisms'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { SmeLoanDetailsModal } from '../../molecules/Modal/SmeLoanDetailsModal'
import { RequestProgressModal } from '../../molecules/Modal/RequestProgressModal'
import { SuccessModal } from '../../molecules/Modal/SuccessModal'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
const Flex = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const SmeLoans = ({ loanType }) => {
    const axiosPrivate = useAxiosPrivate()
    const [loanDetails, setLoanDetails] = useState(null)
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
        dateRange: [],
    })

    const {
        isPending,
        error,
        data: loans,
        refetch,
    } = useQuery({
        queryKey: ['loans'],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/business-loan/search?loanType[0]=${loanType}
        ${filterData.searchTerm ? `&searchTerm=${filterData.searchTerm}` : ''}
        ${
            filterData.dateRange?.length
                ? `&startDate=${filterData.dateRange[0].format(
                      'YYYY-MM-DD',
                  )}&endDate=${filterData.dateRange[1].format('YYYY-MM-DD')}`
                : ''
        }`,
                // &limit=5&page=1
            )

            return response.data.foundLoans.map(item => ({
                ...item,
                id: item.id,
                date: item.createdAt,
                businessName: item.businessInfo?.businessName || '',
                businessUrl: item.businessInfo?.businessUrl || '',
                businessSize: item.businessInfo?.businessSize || '',
                businessSector: item.businessInfo?.businessSector || '',
                yearOfBusiness: item.businessInfo?.yearOfBusiness || '',
                applicantRole: item.businessInfo?.applicantRole || '',
            }))
        },
    })
    const { isMobile } = useScreenSize()
    // eslint-disable-next-line no-unused-vars
    const [openLoanDetailsModal, setOpenLoanDetailsModal] = useState(false)
    const apiRef = useGridApiRef()
    const navigate = useNavigate()

    const businessColumns = [
        {
            field: 'businessName',
            headerName: 'Business Name',
        },
        {
            field: 'businessUrl',
            headerName: 'Business URL',
        },
        {
            field: 'businessSize',
            headerName: 'Business Size',
        },
        {
            field: 'businessSector',
            headerName: 'Business Sector',
        },
        {
            field: 'yearOfBusiness',
            headerName: 'Business Age',
        },
        {
            field: 'applicantRole',
            headerName: 'What Is Your Role',
        },
    ]

    const columns = [
        {
            field: 'fullName',
            headerName: 'Full Name',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => `${value.code}${value.number}`,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            minWidth: 100,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'dob',
            headerName: 'Date of Birth',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'date',
            headerName: 'Date Applied',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
            valueFormatter: ({ value }) => value && formatDate(value),
        },
        {
            field: 'loanAmount',
            headerName: 'Amount',
            valueFormatter: ({ value }) => `NGN ${value.toLocaleString()}`,
        },
        {
            field: 'loanType',
            headerName: 'Loan Type',
        },
        {
            field: 'reasonForLoanRequest',
            headerName: 'Loan Purpose',
        },
        {
            field: 'creditedTime',
            headerName: 'Expecting Funds In',
        },
        ...(loanType === 'Business Loan' ? businessColumns : []),
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="View Details"
                        showInMenu
                        onClick={async () => {
                            setLoanDetails(params.row)
                            setOpenLoanDetailsModal(true)
                        }}
                    />,
                    <GridActionsCellItem
                        label="Delete"
                        showInMenu
                        onClick={() => {}}
                    />,
                ],
            ],
        },
    ]

    const handleLoanTypeChange = loanType => {
        if (loanType === 'Business Loan') {
            navigate('/sme-loans/business')
        } else if (loanType === 'Personal Loan') {
            navigate('/sme-loans/personal')
        }
    }

    useEffect(() => {
        if (!isPending) {
            refetch()
        }
    }, [filterData.searchTerm, filterData.dateRange, loanType])

    useEffect(() => {
        if (apiRef.current?.setColumnVisibility) {
            const hiddenColumns = [
                'loanAmount',
                'loanType',
                'reasonForLoanRequest',
                'creditedTime',
                ...(loanType === 'Business Loan'
                    ? businessColumns.map(col => col.field)
                    : []),
            ]

            for (const hiddenColumn of hiddenColumns) {
                apiRef.current.setColumnVisibility(hiddenColumn, false)
            }
        }
    }, [apiRef, loanType, isPending])

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    SME Loans
                </Text>
                <Text type="p" textWrap="nowrap">
                    All loans applications
                </Text>
            </div>

            <ContentWrapper>
                <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
                    <TableFilter
                        showLoanType
                        showDateRange
                        showsCsvButton
                        showSort={false}
                        filterData={filterData}
                        loanType={loanType}
                        setFilterData={setFilterData}
                        handleLoanTypeChange={handleLoanTypeChange}
                        apiRef={apiRef}
                        csvFileName={`Pro U SME ${loanType}${
                            filterData.dateRange.length
                                ? ` ${
                                      filterData.dateRange[0]?.format(
                                          'YYYY-MM-DD',
                                      ) || ''
                                  } to ${
                                      filterData.dateRange[1]?.format(
                                          'YYYY-MM-DD',
                                      ) || ''
                                  }`
                                : ''
                        }`}
                    />
                </div>
                <TableWrapper>
                    <Table
                        apiRef={apiRef}
                        columns={isMobile ? columns.slice(0, 3) : columns}
                        rows={
                            [...loans].sort(sortWithDate(filterData))
                            // .filter(filterSmeLoan(filterData))
                        }
                        pageSize="8"
                    />
                </TableWrapper>
            </ContentWrapper>

            {loanDetails && (
                <SmeLoanDetailsModal
                    title="Details"
                    open={openLoanDetailsModal}
                    setOpen={setOpenLoanDetailsModal}
                    data={loanDetails}
                    isBusiness={loanType === 'Business Loan'}
                />
            )}
        </Wrapper>
    )
}
