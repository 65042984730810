import React, { useState } from "react";
import { OTPComponent } from "../../organisms/OTPComponent";
import { Link, useSearchParams } from "react-router-dom";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button } from "../../atoms";
import { brandTheme } from "../../../theme";

export const UpdateProfileContact = () => {
  let [searchParams] = useSearchParams();
  let [otpSuccess, setOTPSuccess] = useState(false);

  return (
    <>
      {!otpSuccess ? (
        <OTPComponent
          id={searchParams.get("id")}
          phoneNumber={searchParams.get("phoneNumber")}
          email={searchParams.get("email")}
          useCase={
            searchParams.get("phoneNumber")
              ? "changePhoneNumber"
              : "changeEmail"
          }
          setOTPSuccess={setOTPSuccess}
        />
      ) : (
        <AccountCreationSuccess />
      )}
    </>
  );
};

export const AccountCreationSuccess = () => {
  return (
    <AuthTemplate
      title="Update successfully"
      subtitle="A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets."
    >
      <Link to="/settings/profile">
        <Button width="100%" size="md" bgcolor={brandTheme.brand.primary}>
          Return to profile
        </Button>
      </Link>
    </AuthTemplate>
  );
};
