import React, { useState } from 'react'
import styled from 'styled-components'
import { EmailAndPhoneNumber, Modal } from '../..'
import { HorizontalLine, Icon, Text } from '../../../atoms'
import { screen } from '../../../../theme'
import profileImage from '../../../../assets/pngs/profile-avatar.png'
import { africredColors } from '../../../../theme/colors'
import { formatDate } from '../../../../utils'
// import logo from "../../../../assets/svgs/full_logo.svg";

const Wrapper = styled.div`
    background-color: white;
    height: 100%;
    width: 576px;
    padding: 14px;
    position: relative;
    overflow-y: auto;

    @media only screen and (${screen.md}) {
        padding: 14px 32px;
    }
`
const CloseButton = styled.button`
    border: none;
    background: none;
    position: absolute;
    right: 36px;
    top: 36px;
`

const InfoWrapper = styled.div`
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    gap: 4px;
`
const ProfileImageWrapper = styled.img`
    width: 76px;
    height: 76px;
    padding: 0.2rem;
`
const CurrentStage = styled.div`
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: ${africredColors.primary.green[200]};
`

export const SmeLoanDetailsModal = ({ open, setOpen, data, isBusiness }) => {
    // const [success, setSuccess] = useState(false);

    return (
        <Modal isOpen={open} positionX="end">
            <Wrapper>
                <div display="flex w-full">
                    <Text
                        className="w-5/6 mt-3"
                        type="h3"
                        textWrap="wrap"
                        weight="medium"
                    >
                        Details
                    </Text>

                    <div className="flex flex-col gap-6 mt-6">
                        <InfoWrapper>
                            <div className="w-full flex gap-4 items-center">
                                {/* <ProfileImageWrapper
                                    src={profileImage}
                                    alt="profile"
                                /> */}
                                <Icon type="user-avatar" />
                                <div className="w-full flex flex-col gap-2">
                                    <div className="flex gap-4">
                                        <Text type="h4">{`${data.firstName} ${data.lastName}`}</Text>
                                    </div>

                                    <div className="w-full flex gap-4 justify-between">
                                        <EmailAndPhoneNumber
                                            // email={data?.email}
                                            phoneNumber={`${data.phoneNumber.code}${data.phoneNumber.number}`}
                                        />
                                        <CurrentStage>
                                            <Text>{data.loanType}</Text>
                                        </CurrentStage>
                                    </div>
                                </div>
                            </div>
                        </InfoWrapper>

                        <HorizontalLine />

                        <div className="flex flex-col gap-4">
                            <Text type="p" weight="bold">
                                Basic Details
                            </Text>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <InfoWrapper>
                                    <Text type="h5">Full Name</Text>
                                    <Text
                                        type="p"
                                        color="#5D5D5D"
                                        hovercolor="#5D5D5D"
                                    >
                                        {data.fullName}
                                    </Text>
                                </InfoWrapper>
                                <CopyWrapper text={data.email}>
                                    <InfoWrapper>
                                        <Text type="h5">Email</Text>

                                        <Text
                                            type="p"
                                            color="#5D5D5D"
                                            hovercolor="#5D5D5D"
                                        >
                                            {data.email}
                                        </Text>
                                    </InfoWrapper>
                                </CopyWrapper>
                                <InfoWrapper>
                                    <Text type="h5">Gender</Text>
                                    <Text
                                        type="p"
                                        color="#5D5D5D"
                                        hovercolor="#5D5D5D"
                                    >
                                        {data.gender}
                                    </Text>
                                </InfoWrapper>
                                <CopyWrapper
                                    text={`${data.phoneNumber.code}${data.phoneNumber.number}`}
                                >
                                    <InfoWrapper>
                                        <Text type="h5">Phone</Text>
                                        <Text
                                            type="p"
                                            color="#5D5D5D"
                                            hovercolor="#5D5D5D"
                                        >
                                            {data.phoneNumber.code}
                                            {data.phoneNumber.number}
                                        </Text>
                                    </InfoWrapper>
                                </CopyWrapper>
                                <InfoWrapper className="col-span-full">
                                    <Text type="h5">Date of Birth</Text>
                                    <Text
                                        type="p"
                                        color="#5D5D5D"
                                        hovercolor="#5D5D5D"
                                    >
                                        {formatDate(data.dob)}
                                    </Text>
                                </InfoWrapper>
                            </div>

                            <div className="flex flex-col gap-4">
                                <Text type="p" weight="bold">
                                    Loan Fund Details
                                </Text>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <InfoWrapper>
                                        <Text type="h5">Loan Amount</Text>
                                        <Text
                                            type="p"
                                            color="#5D5D5D"
                                            hovercolor="#5D5D5D"
                                        >
                                            <span className="">NGN</span>{' '}
                                            {data.loanAmount
                                                ? data.loanAmount.toLocaleString()
                                                : ''}
                                        </Text>
                                    </InfoWrapper>
                                    <InfoWrapper>
                                        <Text type="h5">Loan Purpose</Text>
                                        <Text
                                            type="p"
                                            color="#5D5D5D"
                                            hovercolor="#5D5D5D"
                                        >
                                            {data.reasonForLoanRequest || ''}
                                        </Text>
                                    </InfoWrapper>
                                    <InfoWrapper className="col-span-full">
                                        <Text type="h5">Expecting Funs In</Text>
                                        <Text
                                            type="p"
                                            color="#5D5D5D"
                                            hovercolor="#5D5D5D"
                                        >
                                            {data.creditedTime}
                                        </Text>
                                    </InfoWrapper>
                                </div>
                            </div>

                            {isBusiness ? (
                                <div className="flex flex-col gap-4">
                                    <Text type="p" weight="bold">
                                        Business Details
                                    </Text>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <InfoWrapper>
                                            <Text type="h5">Business Name</Text>
                                            <Text
                                                type="p"
                                                color="#5D5D5D"
                                                hovercolor="#5D5D5D"
                                            >
                                                {data.businessInfo
                                                    ?.businessName || ''}
                                            </Text>
                                        </InfoWrapper>
                                        <CopyWrapper
                                            text={
                                                data.businessInfo
                                                    ?.businessUrl || ''
                                            }
                                        >
                                            <InfoWrapper>
                                                <Text type="h5">URL Link</Text>
                                                <Text
                                                    type="p"
                                                    color="#5D5D5D"
                                                    hovercolor="#5D5D5D"
                                                >
                                                    {data.businessInfo
                                                        ?.businessUrl || ''}
                                                </Text>
                                            </InfoWrapper>
                                        </CopyWrapper>
                                        <InfoWrapper>
                                            <Text type="h5">
                                                What Is Your Role
                                            </Text>
                                            <Text
                                                type="p"
                                                color="#5D5D5D"
                                                hovercolor="#5D5D5D"
                                            >
                                                {data.businessInfo
                                                    ?.applicantRole || ''}
                                            </Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <Text type="h5">Business Size</Text>
                                            <Text
                                                type="p"
                                                color="#5D5D5D"
                                                hovercolor="#5D5D5D"
                                            >
                                                {data.businessInfo
                                                    ?.businessSize || ''}
                                            </Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <Text type="h5">Business Age</Text>
                                            <Text
                                                type="p"
                                                color="#5D5D5D"
                                                hovercolor="#5D5D5D"
                                            >
                                                {data.businessInfo
                                                    ?.yearOfBusiness || ''}
                                            </Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <Text type="h5">
                                                Business Sector
                                            </Text>
                                            <Text
                                                type="p"
                                                color="#5D5D5D"
                                                hovercolor="#5D5D5D"
                                            >
                                                {data.businessInfo
                                                    ?.businessSector || ''}
                                            </Text>
                                        </InfoWrapper>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </Wrapper>
            <CloseButton onClick={() => setOpen(false)}>
                <Icon type="close" />
            </CloseButton>
        </Modal>
    )
}

const CopyWrapper = ({ children, text }) => {
    const [copied, setCopied] = useState(false)

    const copyTextToClipboard = text => {
        if (!navigator.clipboard) {
            const textArea = document.createElement('textarea')
            textArea.value = text
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                const successful = document.execCommand('copy')
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
            }
            document.body.removeChild(textArea)
            return
        }
        navigator.clipboard.writeText(text).then(
            () => {
                setCopied(true)

                setTimeout(() => {
                    setCopied(false)
                }, 500)
            },
            err => {
                console.error('Async: Could not copy text: ', err)
            },
        )
    }

    return (
        <div className="relative">
            {children}
            <button
                className="absolute top-3 right-3 bg-[#EEEEEE] text-sm rounded-full px-3"
                onClick={() => copyTextToClipboard(text)}
            >
                <Text type="small" fontSize="14px" weight="medium">
                    {copied ? 'Copied' : 'Copy'}
                </Text>
            </button>
        </div>
    )
}
