import styled, { css } from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Icon } from '../../atoms/Icon'
import profileImage from '../../../assets/pngs/dp.png'
import { Text } from '../../atoms/Text'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { motion } from 'framer-motion'
import { pageAnimationConfig } from '../../../utils'
import { screen } from '../../../theme'
import { SelectInput } from '../../atoms'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import { Skeleton } from '@mui/material'

const Wrapper = styled(motion.div)`
    display: flex;
    gap: 24px;
    flex-direction: column;
`

const highlightStyles = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0 36px 0;
    gap: 16px;
    border-radius: 16px;
    background: transparent;
    width: 100%;

    > div {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        padding: 12px;
    }

    p {
        text-wrap: nowrap;
    }
`

const Highlight = styled.section`
    ${highlightStyles};

    @media only screen and (${screen.lg}) {
        border-radius: 32px;
        padding: 36px 35px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background: ${africredColors.neutrals.white[50]};

        > div {
            flex-direction: row;
            align-items: center;
            padding: 24px;
        }
    }
`

const HighlightBox = styled.div`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
`

const ApplicationStatusArea = styled.section`
    grid-area: quickAction;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    overflow-y: auto;
    width: 100%;
    gap: 16px;

    @media only screen and (${screen.md}) {
        width: 40%;
    }

    @media only screen and (${screen.lg}) {
        width: 30%;
    }
`
const chartStyles = css`
    width: 100%;
    display: flex;
    padding: 23px 20px 32px 20px;
    flex-direction: column;
    align-items: space-between;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    height: 100%;
    overflow-y: auto;
`

const ActivityChart = styled.section`
    ${chartStyles}
    width: 100%;
`

const ChartWrapper = styled.div`
    height: 100%;
    width: 100%;
`

const Details = styled.div`
    display: flex;
    flex-grow: 2;
    gap: 16px;
    height: auto;
    flex-direction: column;

    @media only screen and (${screen.md}) {
        height: 45vh;
        display: flex;
        flex-direction: row;
    }
`

const TransactionTypeSelector = styled.div`
    padding: 8px;
    border-radius: 16px;
    display: flex;
    background: ${africredColors.neutrals.night[50]};
    border-bottom: none !important;
    padding-bottom: 8px !important;
    width: fit-content;
    margin: 16px 0 32px 0;
`

const Options = styled.div`
    display: flex;
    > label {
        display: block;
        padding: 12px 16px;
        border-radius: 8px;
        min-width: 133px;
        text-align: center;
        cursor: pointer;
    }

    > input[type='radio'] {
        display: none;
    }

    input[type='radio']:checked + label {
        background: ${africredColors.neutrals.white[10]};
    }
`

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

export const Analytics = () => {
    const axiosPrivate = useAxiosPrivate()
    const [topUsersFilterOption, setTopUsersFilterOption] = useState('Student')
    const [statsFilter, setStatsFilter] = useState('Student')
    const [transactionTypeFilter, setTransactionTypeFilter] = useState('Loan')
    const [selectedTransactionOption, setSelectedTransactionOption] =
        useState('InReview')
    const auth = useAuth()
    const {
        isPending,
        error,
        data: dashboardData,
    } = useQuery({
        queryKey: ['dashboard-data'],
        queryFn: async () => {
            const response = await axiosPrivate('/transaction/admin-dashboard')
            return response.data
        },
    })
    const chartStep =
        dashboardData?.graphPointsForEarnings?.earningsTotalAmounts?.reduce(
            (partialSum, a) => partialSum + a,
            0,
        )

    const { isPending: statLoading, data: stats } = useQuery({
        queryKey: [
            'fetch-stats',
            transactionTypeFilter,
            selectedTransactionOption,
        ],
        queryFn: async () => {
            // /admin-stats?range=Last 6 months&transactionType=Loan&accountType=Student
            const response = await axiosPrivate(
                `/transaction/stage-admin-stats?transactionType=${
                    transactionTypeFilter === 'Short Term Loan'
                        ? 'Pof'
                        : transactionTypeFilter
                }&status=${selectedTransactionOption}`,
            )
            return response.data
        },
    })

    const activitiesChartStep = stats?.graphPoints?.totalAmounts?.reduce(
        (partialSum, a) => partialSum + a,
        0,
    )

    if (isPending)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                <div className="w-10 h-10">
                    <Icon type="spinner" />
                </div>
                Loading
            </div>
        )

    if (error)
        return (
            <div className="w-full h-full flex items-center justify-center text-gray-400 gap-2">
                An error has occurred: {error.message}
            </div>
        )

    function getBgColor(index) {
        if (index === 0) return africredColors.primary.blue[100]
        if (index === 1) return africredColors.primary.gold[100]
        if (index === 2) return africredColors.secondary.purple[100]
        if (index === 3) return africredColors.primary.green[100]
    }

    function getOptions(step = 3, totalStep = chartStep) {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    position: 'top',
                },
            },
            scales: {
                y: {
                    ticks: {
                        stepSize: totalStep / step,
                    },
                },
            },
            tension: 0.4,
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
        },
        scales: {
            y: {
                ticks: {
                    stepSize: chartStep / 4,
                },
            },
        },
        tension: 0.4,
    }

    const data = {
        labels: dashboardData?.earningInfo?.[
            statsFilter === 'Student'
                ? 'studentGraphPointsForEarnings'
                : 'agentGraphPointsForEarnings'
        ]?.[
            statsFilter === 'Student'
                ? 'studentEarningsMonthNames'
                : 'agentEarningsMonthNames'
        ],
        datasets: [
            {
                label: 'Total',
                data: dashboardData?.earningInfo?.[
                    statsFilter === 'Student'
                        ? 'studentGraphPointsForEarnings'
                        : 'agentGraphPointsForEarnings'
                ]?.[
                    statsFilter === 'Student'
                        ? 'studentEarningsTotalAmounts'
                        : 'agentEarningsTotalAmounts'
                ],
                borderColor: africredColors.neutrals.night[950],
                backgroundcolor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }

    return (
        <Wrapper {...pageAnimationConfig}>
            <Highlight>
                <HighlightCard
                    icon={<Icon type="transaction" />}
                    amount={dashboardData.transactionStats.totalTransactions}
                    text="Total Transactions"
                />
                <HighlightCard
                    icon={<Icon type="card" />}
                    amount={dashboardData.transactionStats.totalLoans}
                    text="Total Loans"
                />
                <HighlightCard
                    icon={<Icon type="proof-of-funds" />}
                    amount={dashboardData.transactionStats.totalPofs}
                    text="Total Short term loans"
                />
                <HighlightCard
                    icon={<Icon type="wallet" />}
                    amount={dashboardData.transactionStats.totalRemittances}
                    text="Total Remittance"
                />

                <HighlightCard
                    backgroundcolor={getBgColor(0)}
                    icon={<Icon type="transaction" />}
                    amount={
                        dashboardData.transactionStats.totalStudentsTransactions
                    }
                    text="Students transactions"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(1)}
                    icon={<Icon type="card" />}
                    amount={
                        dashboardData.transactionStats.totalAgentTransactions
                    }
                    text="Agent Transactions"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(2)}
                    icon={<Icon type="proof-of-funds" />}
                    amount={
                        dashboardData.transactionStats.totalUserTransactions
                    }
                    text="User Transactions"
                />
                <HighlightCard
                    backgroundcolor={getBgColor(3)}
                    icon={<Icon type="wallet" />}
                    amount={
                        dashboardData.transactionStats.totalPartnerTransactions
                    }
                    text="Partner Transactions"
                />
            </Highlight>

            <Details>
                <ActivityChart>
                    <div className="flex w-full items-center justify-between">
                        <div>
                            <Text type="h4">Activity</Text>
                        </div>
                        <div className="flex items-center gap-4">
                            <SelectInput
                                options={['Loan', 'Short Term Loan']}
                                value={transactionTypeFilter}
                                setValue={val => setTransactionTypeFilter(val)}
                                size="sm"
                                bgcolor="transparent"
                                border="none"
                                width="fit-content"
                            />
                        </div>
                    </div>

                    <TransactionTypeSelector>
                        {auth?.enumValues?.adminAnalysisStatusEnum?.map(
                            (item, i) => (
                                <Options>
                                    <input
                                        type="radio"
                                        id={`${item}${i}`}
                                        name="transactionStatus"
                                        value={item}
                                        checked={
                                            item === selectedTransactionOption
                                        }
                                        onChange={e =>
                                            setSelectedTransactionOption(
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <label htmlFor={`${item}${i}`}>
                                        {item}
                                    </label>
                                </Options>
                            ),
                        )}
                    </TransactionTypeSelector>

                    <ChartWrapper>
                        {statLoading ? (
                            <Skeleton active />
                        ) : (
                            <Line
                                options={getOptions(4, activitiesChartStep)}
                                data={{
                                    labels: stats?.graphPoints?.monthNames,
                                    datasets: [
                                        {
                                            label: 'Applications',
                                            data: stats?.graphPoints
                                                ?.totalAmounts,
                                            borderColor:
                                                africredColors.neutrals
                                                    .night[950],
                                            backgroundcolor:
                                                'rgba(255, 99, 132, 0.5)',
                                        },
                                    ],
                                }}
                                // style={{ maxHeight: "250px" }}
                            />
                        )}
                    </ChartWrapper>
                </ActivityChart>
            </Details>

            <Details>
                <ActivityChart>
                    <div className="flex w-full items-center justify-between">
                        <div>
                            <Text type="h4">Earnings</Text>
                        </div>
                        <div className="flex items-center gap-4">
                            <SelectInput
                                options={['Student', 'Agent']}
                                value={statsFilter}
                                setValue={val => setStatsFilter(val)}
                                size="sm"
                                bgcolor="transparent"
                                border="none"
                                width="fit-content"
                            />
                        </div>
                    </div>

                    <ChartWrapper>
                        <Line options={options} data={data} />
                    </ChartWrapper>
                </ActivityChart>

                <ApplicationStatusArea>
                    <div className="flex justify-between items-center gap-4 mb-4">
                        <Text type="h4">Top Users</Text>
                        <SelectInput
                            options={['Student', 'Agent']}
                            value={topUsersFilterOption}
                            setValue={item => setTopUsersFilterOption(item)}
                            size="sm"
                            bgcolor="transparent"
                            border="none"
                            width="fit-content"
                        />
                    </div>
                    {dashboardData?.topUsers?.[
                        topUsersFilterOption === 'Student'
                            ? 'topStudentUsers'
                            : 'topAgentUsers'
                    ]?.map((item, idx) => (
                        <div
                            key={idx}
                            className="flex justify-between items-center gap-4"
                        >
                            <div className="flex items-center gap-3">
                                <img
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                    }}
                                    src={
                                        item.profilePicture
                                            ? item.profilePicture.Location
                                            : profileImage
                                    }
                                    alt="avatar"
                                />
                                <div className="flex flex-col gap-2">
                                    <Text type="p" weight="bold">
                                        {item.firstName} {item.lastName}
                                    </Text>
                                    <Text type="p">15th Jan. 2023</Text>
                                </div>
                            </div>
                            <Text type="p" weight="bold">
                                {item.totalTransactionAmount}
                            </Text>
                        </div>
                    ))}
                </ApplicationStatusArea>
            </Details>
        </Wrapper>
    )
}

const HighlightCard = ({ icon, amount, text, backgroundcolor }) => {
    return (
        <HighlightBox backgroundcolor={backgroundcolor}>
            <div>{icon}</div>
            <div>
                <Text type="h3">{amount}</Text>
                <Text type="p">{text}</Text>
            </div>
        </HighlightBox>
    )
}
