import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { brandTheme } from "./theme";
// import { KYCModal } from "./components/molecules/Modal/KYCModal";
import {
  // AuthContext,
  AuthProvider,
} from "./store/contexts/AuthProvider";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import PersistLogin from "./components/utils/PersistLogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <PersistLogin>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App style={{ backgroundcolor: brandTheme.brand.backgroundSkin1 }} />
        </QueryClientProvider>
      </React.StrictMode>
    </PersistLogin>
    <ToastContainer limit={3} position="top-left" />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
